.left_side {
  background: $black_bg;
  padding: 25px 0;
  @media (min-width: 767px) and (max-width: 1199px) {
    padding: 15px 0;
  }
  @media (min-width: 1920px) {
    padding: 25px 0;
  }

  .logo {
    text-align: center;
    img {
      margin: 10px auto;
      height: 37px;
      @media (max-width: 767px) {
      }
    }
  }

  .catalog_button {
    margin-top: 40px;
    @media (min-width: 1920px) {
      margin-top: 60px;
    }
  }
  .left_sidebar_menus {
    transition: all 500ms;
    margin-top: 40px;
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 8px;
        list-style: none;

        a {
          padding-left: 33px;
          width: 100%;
          display: block;
          text-decoration: none;
          color: #838b97;
          height: 40px;
          line-height: 40px;
          font-size: 13px;
          opacity: 1;
          .theme_bg {
            background-color: $theme_color_deep !important;
            margin-left: 20px;
          }
          svg {
            fill: #757575;
            margin-right: 10px;
          }
          &.active,
          &:hover {
            color: $white;
            svg {
              fill: $white;
            }
          }
          &.logout_btn {
            .fa-power-off {
              font-size: 20px;
            }
          }
        }
        &:hover {
          background-color: $theme_color_deep;
          a {
            color: $black;
            svg {
              fill: $black;
            }
          }
        }
      }
    }
  }
  .category {
    padding-right: 25px;
    width: 100%;
    .category_filter_btn {
      padding: 0 !important;
      background: none;
      color: $white;
      border: none;
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:focus {
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
        background: none !important;
      }
    }
    .category_menu {
      width: 100% !important;
      background: $black_bg !important;
      border: none !important;
      .dropdown-item {
        padding: 0 !important;
        background: none !important;
        a {
          display: block;
          margin: 5px 0 !important;
          font-size: 14px;
          padding: 5px;
          color: #838b97;
          text-decoration: none;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  .rotate_text {
    color: #272727;
    text-align: center;
    font-size: 130px;
    font-weight: 500;
    text-transform: uppercase;
    writing-mode: vertical-lr !important;
    text-orientation: mixed;
    line-height: 1;
    white-space: nowrap;
    margin-top: 40px;
  }

  .searchbox {
    @media (max-width: 767px) {
      display: block !important;
      width: 90%;
      margin-top: 20px;
    }
    @media (min-width: 768px) {
      display: none !important;
    }
  }
}
.reducedMenu {
  .left_sidebar_menus {
    ul {
      li {
        text-align: center;
        padding-left: 0px;
      }
    }
  }
  .left_side {
    padding-left: 0px;
  }
  .rotate_text {
    font-size: 50px;
    margin: 0 auto;
  }
  .menuText {
    // transform: display 200ms;
    display: none;
  }
}

.extendedMenu {
  .left_sidebar_menus {
    ul {
      li {
        a {
          display: grid;
          grid-template-columns: 30px 1fr;
          align-items: center;
        }
        margin-right: 30px;
        border-radius: 0 25px 25px 0;
      }
    }
  }
}

.my_games_menu {
  img {
    width: 25px;
    opacity: 0.5;
    margin-right: 10px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
  .menuText {
    padding-left: 3px;
  }
}
.refer_and_earn_menu,
.my_game_menu {
  a {
    color: $white;
  }

  img {
    width: 25px;
    opacity: 0.5;
    margin-right: 8px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    // padding-left: 2px;
  }

  // .fa-user-friends {
  //   margin-right: 15px;
  //   font-size: 18px;
  //   opacity: 0.5;
  // }

  // .menuText {
  //   opacity: 0.5;
  //   padding-left: 4px;
  // }
  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }
}

.store_menu {
  a {
    color: $white;
  }

  img {
    width: 25px;
    opacity: 0.5;
    margin-right: 8px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    // padding-left: 2px;
  }

  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }
}

.free_spins_menu {
  a {
    color: $white;
  }

  img {
    width: 20px;
    opacity: 0.5;
    margin-right: 8px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    // padding-left: 2px;
  }

  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }
}

.responsible_gamings_menu,
.verification_menu {
  a {
    color: $white;
  }

  img {
    width: 23px;
    opacity: 0.5;
    margin-right: 8px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    // padding-left: 2px;
  }

  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }
}

.logoutMenu {
  a {
    color: $white;
  }

  img {
    width: 24px;
    opacity: 0.5;
    margin-right: 8px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    // padding-left: 2px;
  }

  // .fa-user-friends {
  //   margin-right: 15px;
  //   font-size: 18px;
  //   opacity: 0.5;
  // }

  // .menuText {
  //   opacity: 0.5;
  //   padding-left: 4px;
  // }
  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 10px !important;
  }
}

.vipMenu,
.pormotionMenu {
  a {
    color: $white;
  }

  img {
    width: 22px;
    opacity: 0.5;
    margin-right: 10px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    padding-left: 2px;
  }

  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }
}

.pre_menu_top_area {
  padding-top: 50px;
}
.pre_left_bar {
  .pre_menu_top {
    background: #232323;
    margin: 10px;
    border-radius: 10px;
    // margin-top: 75px;
    text-align: center;
    .user_avatar {
      position: relative;
      .language_dropdown {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      // margin-top: -50px;
      img.avatar_image {
        margin-top: -35px;
        cursor: pointer;
        width: 70px;
        margin-bottom: 15px;
      }
      .userName {
        display: block;
        color: #fff;
        text-decoration: none;
      }
    }
    .user_balance_area {
      padding: 10px;
      .user_balance {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #000;
        color: yellow;
        border-radius: 5px;
        font-weight: bold;
        .fa-plus-circle {
          float: right;
          margin-right: 15px;
          color: yellow;
          font-size: 20px;
          margin-top: 10px;
        }
      }

      ul {
        margin-top: 15px !important;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;

        li {
          cursor: pointer;
          height: 60px;
          background-color: #303030;
          border-radius: 5px;
          a {
            text-decoration: none;
          }
          .icon {
            padding-top: 4px;
            font-size: 24px;
            color: #717171;
          }
          .text {
            font-size: 12px;
            color: #717171;
          }

          &:hover {
            .icon,
            .text {
              color: $theme_color_light;
            }
          }
        }
        &.next_vip_level {
          margin-top: 5px !important;
          display: block;
          li {
            background-color: unset;
            height: auto;
          }
        }
      }
    }
  }

  .pre_menu_area {
    ul {
      background: #232323;
      margin: 10px !important;
      border-radius: 10px;
      padding: 10px !important;
      // margin-bottom: 30px !important;
      li {
        border-bottom: 1px solid #000;
        a {
          display: grid;
          align-items: center;
          grid-template-columns: 24px 1fr;
          opacity: 0.7;
          padding: 5px 0;
          text-decoration: none;
          &:hover {
            opacity: 1;
          }
        }
        .icon {
          background: url("../assets/images/common/all_bg.png");
          width: 24px;
          height: 24px;
        }
        .text {
          color: #fff;
          padding-left: 5px;
          font-size: 14px;
        }

        &.homeMenu {
          .icon {
            background-position: -48px -447px;
          }
        }
        &.allGamesMenu {
          .icon {
            background-position: -149px -150px;
            // background-image: unset;
          }
        }
        &.slotsMenu {
          .icon {
            background-position: -96px -448px;
            // background-image: unset;
          }
        }
        &.liveCasinoMenu {
          .icon {
            background-position: -291px -448px;
            // background-image: unset;
          }
        }
        &.newGamesMenu {
          .icon {
            background-position: -315px -380px;
            // background-image: unset;
          }
        }
        &.crashGamesMenu {
          .icon {
            background-position: -339px -446px;
            // background-image: unset;
          }
        }
        &.casinoGamesMenu {
          .icon {
            background-position: -240px -447px;
            // background-image: unset;
          }
        }

        &.jackpotsMenu {
          .icon {
            background-position: -570px -446px;
            // background-image: unset;
          }
        }
        &.zinoExclusiveMenu {
          .icon {
            background-position: -387px -381px;
          }
        }
        &.promotoinsMenu {
          .icon {
            background-position: -339px -448px;
            // background-image: unset;
          }
        }
        &.vipClubMenu {
          .icon {
            background-position: -194px -449px;
            // background-image: unset;
          }
        }

        &.active,
        &:hover {
          a {
            opacity: 1;
          }
          .icon {
            border-radius: 50%;
            background-color: $theme_color_light;
          }
          .text {
            color: $theme_color_light;
          }

          &.homeMenu {
            .icon {
              background-position: -48px -477px;
            }
          }
          &.slotsMenu {
            .icon {
              background-position: -96px -477px;
            }
          }
          &.allGamesMenu {
            .icon {
              background-position: -149px -188px;
              // background-image: unset;
            }
          }
          &.liveCasinoMenu {
            .icon {
              background-position: -291px -477px;
            }
          }
          &.newGamesMenu {
            .icon {
              background-position: -315px -410px;
            }
          }
          &.casinoGamesMenu {
            .icon {
              background-position: -241px -477px;
            }
          }
          &.crashGamesMenu {
            .icon {
              background-position: -339px -476px;
              // background-image: unset;
            }
          }
          &.jackpotsMenu {
            .icon {
              background-position: -572px -476px;
            }
          }
          &.zinoExclusiveMenu {
            .icon {
              background-position: -387px -410px;
            }
          }
          &.promotoinsMenu {
            .icon {
              background-position: -339px -477px;
            }
          }
          &.vipClubMenu {
            .icon {
              background-position: -194px -478px;
            }
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
  }

  &.sidebar_container .sidebar_area.menu_left_bar {
    width: 230px;
    // background: unset !important;
    @media (max-width: 767px) {
      background: #000 !important;
      width: 80vw;
    }
    .common_btn.LogInBtn {
      height: 30px;
      padding: 0 15px !important;
      line-height: 30px !important;
      font-size: 14px !important;
    }
  }
  .left_side {
    background: unset;
    padding: 9px 0 0 0;
  }

  // @media (min-width: 1400px) {
  //   left: unset !important;
  //   transform: unset !important;

  //   .show_bar {
  //     left: unset !important;
  //     transform: unset !important;
  //     height: auto !important;
  //   }
  // }
}

.tickets_menu {
  a {
    color: $white;
  }

  img {
    width: 25px;
    opacity: 0.5;
    margin-right: 8px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }

  .menuText {
    // padding-left: 2px;
  }

  // .fa-user-friends {
  //   margin-right: 15px;
  //   font-size: 18px;
  //   opacity: 0.5;
  // }

  // .menuText {
  //   opacity: 0.5;
  //   padding-left: 4px;
  // }
  &:hover {
    .fa-user-friends {
      opacity: 1;
    }
    .menuText {
      opacity: 1;
    }
  }
}
