@font-face {
  font-family: "Myriad Pro";
  src: url("../assets/fonts/MyriadPro-Bold.woff") format("woff2"),
    url("../assets/fonts/MyriadPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Myriad Pro";
//   src: url("MyriadPro-BoldIt.woff2") format("woff2"),
//     url("MyriadPro-BoldIt.woff") format("woff");
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Myriad Pro";
//   src: url("MyriadPro-BoldCondIt.woff2") format("woff2"),
//     url("MyriadPro-BoldCondIt.woff") format("woff");
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Myriad Pro";
//   src: url("../assets/fonts/MyriadPro-BoldCond.woff") format("woff2"),
//     url("../assets/fonts/MyriadPro-BoldCond.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Myriad Pro";
//   src: url("MyriadPro-SemiboldIt.woff2") format("woff2"),
//     url("MyriadPro-SemiboldIt.woff") format("woff");
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "Myriad Pro";
  src: url("../assets/fonts/MyriadPro-Light.woff2") format("woff2"),
    url("../assets/fonts/MyriadPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../assets/fonts/MyriadPro-Regular.woff2") format("woff2"),
    url("../assets/fonts/MyriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Myriad Pro";
//   src: url("../assets/fonts/MyriadPro-Cond.woff2") format("woff2"),
//     url("../assets/fonts/MyriadPro-Cond.woff2") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Myriad Pro";
//   src: url("MyriadPro-CondIt.woff2") format("woff2"),
//     url("MyriadPro-CondIt.woff") format("woff");
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "Myriad Pro";
  src: url("../assets/fonts/MyriadPro-Semibold.woff2") format("woff2"),
    url("../assets/fonts/MyriadPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
