.promotion_area {
  ul.content-sub-menu {
    li {
      margin: 0 10px;
      display: inline-block;
      a {
        padding: 5px 10px;
        color: #fff;
        &:hover {
          text-decoration: none;
          color: #0096ce;
          background-color: #242a38;
        }
        &.active {
          color: #f8bd35;
          border: 1px solid #f8bd35;
        }
      }
    }
  }

  .common_heading_area {
    .common_tab_menu {
      li {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .promotions {
    color: $white;
    .single-promotion {
      text-align: center;
      margin-bottom: 35px;
      img {
        width: 100%;
        @media only screen and (max-width: 767px) {
          height: auto;
        }
      }
      h5 {
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0 5px 0;
        text-align: left;
        height: 40px;
        color: $white;
      }
      p {
        /*padding-bottom: 10px;*/
        color: $white;
        line-height: 19px;
        font-size: 16px;
        text-align: justify;
        /*overflow: hidden;*/
        // height: 78px;
        @media only screen and (max-width: 767px) {
          height: auto;
        }

        .green-btn {
        }
        .green-btn:hover {
          color: #fff;
        }
      }

      p:last-child {
        padding-top: 5px;
      }
      .common_brown_button {
        display: inline-block;
        &:hover {
          text-decoration: none;
        }
      }
      .promotion_main_text_area {
        /*padding-bottom: 10px;*/
        position: relative;
        color: $white;
        line-height: 19px;
        font-size: 16px;
        text-align: justify;
        /*overflow: hidden;*/
        // height: 78px;

        @media only screen and (max-width: 767px) {
          height: auto;
        }
        .promotion_main_text {
          // position: absolute;
          width: 100%;
          z-index: 1;
          background-color: $black;
          // background-color: $main_bg;
          padding-bottom: 15px;

          overflow-y: hidden;
          transition: all 0.5s ease 0s;
          min-height: 58px;
          /*                    span{

                                            transition: height 1s ease-out;
                                        }*/

          @media (max-width: 767px) {
            position: relative;
            max-height: 100px;
          }

          a {
            color: #c79e6a;
          }

          .fold_in {
            display: none;
          }
          &.open {
            max-height: 1000px;

            transition: 1s;
          }
        }
        .lessMoreBtn {
          text-decoration: none;
          color: $white;
          // position: absolute;
          right: 0;
          bottom: -42px;
          z-index: 1;
          cursor: pointer;
          &:hover {
            color: $theme_color_light;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .single-promotion img {
      width: 100%;
    }
  }

  .promotion_action_area {
    padding-top: 15px !important;
    .common-btn {
      padding: 10px;
    }
    .yellow-btn {
      padding: 10px;
      line-height: 18px;
      font-size: 14px;
      border: none;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: #d0b700;
      color: #fff;
    }
    .common_brown_button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    .common_btn {
      display: inline-block;
      height: 35px;
      line-height: 35px !important;
      padding: 0 15px !important;
      @media (max-width: 991px) {
        padding: 0 10px !important;
      }
    }
  }
}

.promotionVideoArea {
  .promotionTitle {
    font-weight: 500;
    margin: 15px auto 15px auto;
    font-size: 22px;
    text-align: center;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }

  .video-container iframe,
  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    margin: 0;
  }
}
