$walletInputBg: #202020;

.svg_icon {
  width: 20px;
  height: 20px;
  display: inline;
}

.radio_area {
  width: 100%;
  cursor: pointer;
  .radio_check_and_text_area {
    padding: 0 15px;
    // margin-top: 10px;
    margin-bottom: 10px;
  }
  .radio_box {
    vertical-align: sub;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: inline-block;
    border: 2px solid #a9a9a9;
    border-radius: 25px;
    text-align: center;
    .radio_checked_box {
      width: 10px;
      height: 10px;
      border-radius: 25px;
      margin: 3px auto 0 auto;
      display: none;
    }
  }
  .radio_text {
    display: inline-block;
    font-weight: 600;
    width: calc(100% - 35px);
    vertical-align: unset;
    line-height: 18px;
  }
}
input[type="radio"] {
  display: none;
  &:checked + label {
    .radio_box {
      border: 2px solid $custom-hover-color;
      background: $theme_color_light;
    }
    .radio_checked_box {
      display: block;
    }
    .reg_bonus_banner {
      max-height: 72px;
    }
  }
}

.deposit_block {
  border-radius: 8px;
  padding: 5px 5px;
  width: 220px;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  background: $walletInputBg;

  @media (max-width: 767px) {
    width: 100px;
  }

  .wallets_dropdown {
    display: flex;
    padding: 5px;
    background: none !important;
    border: none;
    &:after {
      display: none !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: none !important;
      box-shadow: none !important;
    }
  }
  .deposit_icon {
    border-radius: 6px;
    height: 18px;
    width: 18px;
    text-align: center;
    // line-height: 28px;
    // margin-left: 2px;
  }

  .wallet_caret {
    padding-left: 10px;
    line-height: 30px;
    .uparrow {
      display: none;
    }
    .downarrow {
      display: inline;
    }
  }
  .dropdown.show {
    .wallet_caret {
      .uparrow {
        display: inline;
      }
      .downarrow {
        display: none;
      }
    }
  }
  .deposit_amount {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: $white;
    padding-right: 2px;
  }
  .deposit_button {
    text-align: right;
    transition: all 500ms;
    background: $theme_color_light;
    border-radius: 5px;
    opacity: 1;
    &:hover {
      background-color: $orenge;
    }
    .deposit_btn {
      text-decoration: none;
      color: $white;
      font-style: normal;
      font-weight: bold;
      font-size: 12px !important;
      line-height: 24px;
      text-transform: capitalize;
      display: block;
      text-align: center;
      // box-shadow: 0px 3px 0px #5e2d94;
      border-radius: 4px;
      color: #000 !important;      
    }
  }

  @media (max-width: 575px) {
    display: inline-block;
    // width: 100%;
    min-width: unset;
    padding: 4px 5px;
    .dropdown {
      height: 30px;
      margin-top: -6px;
      .profile_dropdown {
        padding: 0px;
      }
    }
  }
}

.wallets_menu {
  white-space: nowrap;
  padding: 15px 15px 15px 15px !important;
  margin-top: 12px;
  width: 220px;
  button {
    background: none;
    padding: 0;
    margin: 0;
  }
  div,
  span,
  input {
    color: black !important;
    font-size: 13px;
  }
  .modal_top_arrow {
    background: $white;
    width: 15px;
    height: 15px;
    top: -7px;
    position: absolute;
    transform: rotate(45deg);
  }
  .wallets_head {
    padding-bottom: 10px;
    border-bottom: 2px solid #dddddd;
  }
  .wallets_balance {
    padding: 3px 0;
    line-height: 33px;
    height: 33px;
    cursor: pointer;
    &:hover {
      background: #eeeeee;
    }
    .currency {
      display: flex;
      .currency_icon {
        display: flex;
        padding-top: 5px;
      }
      .currency_name {
        text-align: right;
        flex-grow: 1;
      }
    }
  }
  .progress_bar {
    width: 41px;
    margin-left: auto;
    .progress {
      height: 3px;
      background: rgba(172, 115, 234, 0.4);
      .progress-bar {
        width: 0;
        background: #ac73ea;
      }
    }
  }
  .wallets_bottom {
    padding-top: 10px;
    .deposit_button {
      width: 140px;
    }
  }
}

.modal-content {
  background: none !important;
  margin: 0 auto !important;
}

.cashier_modal {
  background: $site_bg;
  border-radius: 8px;
  padding: 30px 30px !important;
  margin: 0 auto;
  color: $white;
  @media (max-width: 575px) {
    padding: 10px !important;
  }
  .modal_close {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #454568;
    color: #a8ace2;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: -20px;
    top: -20px;
    cursor: pointer;
    @media (max-width: 767px) {
      right: 5px;
      top: 5px;
    }
  }
  .modal_headline {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #dddff8;
  }
  .login_mode {
    border-radius: 8px;
    width: fit-content;
    margin: 0 auto;
    background: $walletInputBg;
    .mode_btn {
      margin: 5px;
      display: inline-block;
      height: 36px;
      text-decoration: none;
      width: 100px;
      // border-radius: 6px;
      line-height: 36px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      text-transform: capitalize;
      color: $white;
      &:hover {
        background: $theme_color_light;
        color: $black;
      }
      &.active {
        background: $theme_color_light;
        color: $black;
      }
      @media (max-width: 767px) {
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .form_area {
    margin: 20px 0 10px;
    .currency_select_menu {
      .select_crypto {
        text-align: left;
        width: 100%;
        background-color: $walletInputBg;
        border: none;
        height: 48px;
        &:active,
        &:focus {
          background-color: $walletInputBg !important;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }

        &.dropdown-toggle {
          background-color: $walletInputBg !important;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }
        &::after {
          float: right;
          margin-top: 10px;
        }
      }
      .wallets_menu.dropdown-menu {
        width: 100%;
        background-color: $walletInputBg;
        padding: 0 !important;
        color: $white;
        button {
          height: 50px;
          padding: 0;
          padding: 0 20px;
          .wallets_balance {
            padding: 0;
            color: $white;
            &:hover {
              background: none;
            }
            .amount {
              color: $white !important;
            }
          }
          &:hover {
            background-color: $theme_color_light;
          }
        }
      }
    }
    .bordered_text {
      background: $black_bg;
      margin: 20px 0;
      padding: 20px;
      border-radius: 5px;
      width: 800px;
      max-width: 80vw;
    }

    .input_item {
      margin-bottom: 15px;
      .name_value {
        background: $walletInputBg;
        padding: 20px;
        border: none;
        color: $white;
        height: 48px;
        box-shadow: none;
        border-radius: 5px;
      }
      .currency_name {
        line-height: 48px;
        background: $walletInputBg;
        padding: 0 10px;
        color: $white;
      }
      .max_lavel {
        background: #304a71;
        border: none;
        color: $white;
      }
    }

    .walletAddress {
      margin: 20px 0;
      position: relative;
      .in_input_icon {
        color: #fff;
        font-size: 12px;
        position: absolute;
        right: 10px;
        top: 35px;
        cursor: pointer;
      }
    }

    .qr_code.in_wallet_modal {
      text-align: center;
      .wallet_qr {
        border-radius: 4px;
        max-width: 160px;
        max-height: 160px;
      }
    }
  }
}

.fiat_selectors_modal {
  .privacy_text {
    font-size: 14px;
    text-align: left;
  }
  .fiat_selectors {
    .fiat_select {
      display: inline-block;
      padding: 10px 15px;
      white-space: nowrap;
      .radio_check_and_text_area {
        display: flex;
        .radio_text {
        }
        .currency {
          color: white;
          display: inline-block;
          line-height: 20px;
        }
        .radio_box {
          border-radius: 0px !important;
        }
      }
    }
  }
}

.modal {
  .modal-90w {
    max-width: 90%;
  }
  .modal-80w {
    max-width: 80%;
  }
  .modal-70w {
    max-width: 70%;
  }
  .modal-60w {
    max-width: 60%;
  }
  .modal-50w {
    max-width: 50%;
  }
}
.recovery_success {
  color: $white;
}

.cashierModal {
  .modal-body {
    background-color: #191919;
    color: #fff;
  }
}

$text_color: #fff;
$light_grey: #ccc;
.banking_area {
  // margin: 20px auto;
  padding: 20px 0;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    padding: 10px;
  }
  .banking_heading_area {
    .common_title {
    }
    p {
      color: $text_color;
    }
  }

  .banking_list_area {
    .small_info {
      font-style: italic;
      font-size: 14px;
      margin-bottom: 5px;
      color: $text_color;
    }
    .single_banking {
      margin-bottom: 15px;
      background-color: #000;
      /*height: 100px;*/
      padding: 10px;
      ul {
        /*display: flex;*/
        align-items: baseline;
        display: grid;
        grid-template-columns: auto auto auto auto;
        @media (max-width: 767px) {
          display: block;
          text-align: center;
        }
        li {
          color: $text_color;
          /*width: 25%;*/
          .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            @media (max-width: 767px) {
              display: none;
            }
          }
          .method_logo {
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
            height: 50px;
            background-repeat: no-repeat;
            border-radius: 3px;
            background-position: center;
            margin: 10px 0;
            /*-ms-flex-preferred-size: 160px;*/
            /*background-size: 75%;*/
            // background-color: #fff;
            width: 160px;
            background-size: contain;
            filter: grayscale(100%);
            @media (min-width: 768px) and (max-width: 991px) {
              width: 140px;
            }
            @media (max-width: 767px) {
              float: left;
            }
          }
          .common_brown_button {
            vertical-align: middle;
          }
          &.payment_method_name {
            width: 200px;
          }

          &.min_payment_amount {
            @media (max-width: 991px) {
              padding-top: 0px;
              .helper {
                display: none;
              }
            }
          }

          .payment_content {
            vertical-align: middle;
            @media (max-width: 991px) {
              display: inline-block;
            }
            @media (max-width: 767px) {
              /*float: right;*/
            }
          }
          @media (max-width: 767px) {
            width: auto;
            text-align: left;
            /*                        .helper{
                                                    display: none;
                                                }*/

            margin-bottom: 10px;
            &.payment_logo_name {
            }
            &.min_payment_amount,
            &.payment_method_name,
            &.payment_method_name,
            &.registration_amount {
              /*                            background-color: #fff;
                                                        color: #000;
                                                        padding: 10px;*/
            }
            &.registration_amount {
              text-align: center;
            }
            &:last-child {
              margin-bottom: 0px;
            }

            &.payment_logo_area {
              .payment_text_mobile {
                display: none;
                .helper {
                  display: inline-block;
                  height: 100%;
                  vertical-align: middle;
                }
                .method_logo_text {
                  vertical-align: middle;
                  display: none;
                }
                @media (min-width: 320px) and (max-width: 359px) {
                  display: none;
                }
              }
            }
            @media (min-width: 320px) and (max-width: 359px) {
              .method_logo {
                width: 100%;
              }
            }
          }

          .payment_text_mobile {
            float: left;
            display: none;
          }

          .common_brown_button {
            min-width: 145px;
          }
        }
      }
    }
  }
}

.payment_method_list {
  .banking_area {
    .banking_list_area {
      .small_info {
        font-style: italic;
        font-size: 14px;
        margin-bottom: 5px;
        color: $text_color;
      }
      .single_banking {
        position: relative;
        padding: 0px;
        label {
          padding: 10px;
          width: 100%;
          margin: 0px;
          cursor: pointer;
        }
        input[type="radio"] {
          display: none;
        }

        .payment_check_mark {
          width: 20px;
          height: 20px;
          border: 2px solid $theme_color_deep;
          position: absolute;
          top: 46px;
          left: 20px;
          z-index: 1;
          display: none;
        }

        input[type="radio"]:checked + label {
          border: 1px solid $theme_color_deep;
          padding: 9px 10px;
        }

        .selected_method_icon {
          display: none;
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 1;
          color: $theme_color_deep;
          line-height: 15px;
          font-size: 16px;
          text-align: center;
        }

        input[type="radio"]:checked ~ label.payment_check_mark {
          .selected_method_icon {
            display: block;
          }
        }

        .payment_logo_area {
          position: relative;
          .icon_popover {
            position: absolute;
            top: 5px;
            left: 8px;
            z-index: 1;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #000;
            color: #fff;
            line-height: 15px;
            font-size: 11px;
            text-align: center;
          }
        }
      }
    }
  }
}

label.check_box {
  margin: 0px;
  margin-right: 10px;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border: 1px solid $theme_color_deep;
  border-radius: 2px;
  padding-top: 2px;
  padding-left: 2px;
  .fa-check {
    display: none;
    font-size: 10px;
    color: $text_color;
  }
}
input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + label.check_box {
  .fa-check {
    display: block;
    margin-top: 5px;
  }
}

.campaigns-wrap {
  margin: 15px 0;
  .campaigns{
    .campaign{
      display: flex;
    }
  }
}

.cashier_amount_lavel {
  display: inline-block;
  .radio_check_and_text_area {
    padding: 0;
    .radio_box {
      border-radius: 0px !important;
    }
  }
}

.chimp-radio-field {
  display: grid;
  grid-template-columns: auto auto auto;
}

.modal_amount_area {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-bottom: 10px;
}


.withdrawalOptionMenu{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px !important ;
  li{
    label{
      cursor: pointer;
      padding: 3px 10px;
    }
    input[type="radio"] {
      // display: block;
      &:checked + label {
        border: 1px solid $theme_color_deep;
        
      }
    }
  }
}
