.banner_img {
  img {
    // animation: bannerImage 15s linear 5s infinite reverse backwards;
  }
}

@keyframes bannerImage {
  0% {
    transform: translate(0);
  }

  10% {
    transform: translate(-5px, -5px);
  }

  50% {
    transform: translate(5px, -5px);
  }

  30% {
    transform: translate(-5px, 5px);
  }

  40% {
    transform: translate(5px, 5px);
  }

  50% {
    transform: translate(-5px, -5px);
  }

  60% {
    transform: translate(5px, -5px);
  }

  70% {
    transform: translate(-5px, 5px);
  }

  80% {
    transform: translate(-5px, -5px);
  }

  90% {
    transform: translate(5px, -5px);
  }

  100% {
    transform: translate(0);
  }
}

@-webkit-keyframes greenBtn {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(127, 248, 0, 0.5);
  }
  60% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 35px rgba(127, 248, 0, 0);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(127, 248, 0, 0);
  }
}
@keyframes greenBtn {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(127, 248, 0, 0.5);
  }
  60% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 35px rgba(127, 248, 0, 0);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(127, 248, 0, 0);
  }
}


@-webkit-keyframes blueBtn {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(24, 156, 186, 0.5);
  }
  60% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 35px rgba(127, 248, 0, 0);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(127, 248, 0, 0);
  }
}
@keyframes blueBtn {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(24, 156, 186, 0.5);
  }
  60% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 35px rgba(127, 248, 0, 0);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(127, 248, 0, 0);
  }
}

@-ms-keyframes spin {
  from { -ms-transform: rotate(0deg); }
  to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
