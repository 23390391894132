.bonus_banner_container {
  margin-top: 40px;
}
.bonus_banner {
  position: relative;
  border-radius: 0 5px 5px 0;
  @media (max-width: 991px) {
    background: $theme_color_deep;
    border-radius: 5px;
  }

  .bonus_banner_img {
    // @media (min-width: 992px) and (max-width: 1199px) {
    //   height: 300px !important;
    // }
    // @media (min-width: 1200px) {
    //   height: 355px !important;
    // }
  }

  .theme_bg_overlap {
    position: absolute;
    bottom: 170px;
    background: $theme_color_light;
    width: 100px;
    height: 132px;
    @media (min-width: 992px) and (max-width: 1199px) {
      bottom: 87px;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      bottom: 129px;
    }
  }
  .clip_area {
    padding: 45px 0 15px;

    bottom: 0;
    left: 0;
    position: absolute;
    height: 302px;
    width: 59%;
    background: $black_bg;
    clip-path: polygon(10% -1%, 100% 0, 85% 100%, 73% 101%, 0 100%, 0 41%);
    @media (max-width: 991px) {
      position: unset;
      width: 100%;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      height: 261px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 220px;
      padding: 5px 0 15px;
    }

    .bonus_heading {
      font-size: 60px;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      color: $white;
      @media (max-width: 575px) {
        font-size: 50px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 50px;
      }
    }
    .bonus_sub_headline {
      font-size: 20px;
      text-align: center;
      color: $white;
      @media (max-width: 575px) {
        font-size: 17px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 18px;
      }
    }
    .custom_for_banner {
      padding: 0 40px;
    }
  }
}

.landing_banner_BAK {
  // border-left: 1px solid $theme_color_deep;
  // border-top: 1px solid $theme_color_deep;
  position: relative;
  @media (max-width: 767px) {
    display: none !important;
  }
  &.mobile {
    display: none !important;
    @media (max-width: 767px) {
      display: block !important;
    }
    .slick-next,
    .slick-prev {
      z-index: 6;
    }
    .banner_left {
      width: auto;
      height: 166px;
      .banner_img {
        margin-top: 0;
      }
    }
    .banner_right {
      .banner_text {
        .sub_headline {
          font-size: 17px;
        }
        .headline {
          font-size: 20px;
        }
      }
    }
  }
  .slick-next {
    right: 2px;
    display: none !important;
  }
  .slick-prev {
    left: 2px;
    display: none !important;
  }
  .banner_left {
    overflow: hidden;
    position: relative;
    // height: 100%;
    height: 365px;
    width: 400px;
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 340px;
    }
    .baner_clip {
      background: $theme_color_deep;
      clip-path: polygon(0 0, 60% 0, 100% 100%, 0 100%);
      height: 100%;
      position: relative;
      z-index: 1;
    }
    .banner_img {
      z-index: 1;
      // position: absolute;
      // bottom: -10px;
      margin-top: 70px;
      right: 0;
      @media (min-width: 992px) and (max-width: 1199px) {
        right: 15px !important;
      }
      img {
        width: 100%;
        height: 340px;
        z-index: 2;
      }
      video,
      img.video {
        width: 100%;
        height: auto;
        // margin-left: -60px;
      }
    }
    &.rouletteAnimated {
      .banner_img {
        transform: rotate(90deg);
        position: absolute;
        bottom: 2px;
        left: -165px;
        video {
          width: 75%;
        }
      }
    }
  }

  .banner_right {
    // margin-top: 50px;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-top: 30px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .banner_text {
      padding-left: 50px;

      .common_btn {
        // margin-left: 100px !important;
      }
      @media (max-width: 575px) {
        padding-left: 0 !important;
        text-align: center;
        .common_btn {
          margin: 0 auto !important;
        }
      }
      .sub_headline {
        font-size: 35px;
        color: $white;
      }
      .headline {
        font-size: 50px;
        color: $white;
        line-height: 1;
      }
      .description {
        padding: 5px 0 20px;
        font-size: 17px;
        color: #73797c;
        line-height: 1;
      }
    }
  }
  .banner_feature {
    width: 70%;
    position: absolute;
    right: 0px;
    bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
    z-index: 0;
    margin-top: 30px;
    @media (max-width: 575px) {
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .chimp {
      background: #282829;
      border-radius: 10px;
      width: 100%;
      display: grid;
      grid-template-columns: 45% 55%;
      height: 100%;
      min-height: 120px;

      &.with_clip {
        clip-path: polygon(-3% -25%, 100% 0%, 100% 100%, 16% 100%);
      }
      &.last {
        @media (max-width: 575px) {
          margin-top: 50px;
        }
      }
      .chimp_img {
        position: relative;

        // img {
        //   width: 100px;
        //   position: absolute;
        //   top: -18px;
        //   right: 20px;
        //   z-index: 5;
        // }

        &.casinoGames {
          img {
            width: 130px;
            position: absolute;
            top: -18px;
            right: 20px;
            z-index: 5;
          }
        }
        &.liveCasino {
          img {
            width: 130px;
            position: absolute;
            top: -66px;
            right: 30px;
            z-index: 5;
          }
        }
      }
      .chimp_text {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .chimp_sub_headline {
          font-size: 18px;
          line-height: 1;
          color: $white;
        }
        .chimp_headline {
          font-size: 18px;
          text-transform: uppercase;
          line-height: 1;
          text-decoration: none;
          &:hover {
            color: $theme_color_deep;
          }
        }
        .chimp_dsc {
          font-size: 15px;
          color: #73797c;
          height: 1;
          padding: 5px 0;
        }
      }
    }
  }
}

.landing_banner {
  // padding-top: 30px;
  position: relative;
  .single_banner {
    position: relative;
    .banner_image {
      width: 100%;
      // @media (min-width: 1600px) {
      //   width: auto;
      //   float: right;
      // }

      &.desktop_banner {
        display: none;
        @media (min-width: 1200px) {
          display: block;
        }
      }
      &.tab_banner {
        display: none;
        @media (min-width: 768px) and (max-width: 1199px) {
          display: block;
        }
      }
      &.mobile_banner {
        display: none;
        @media (max-width: 767px) {
          display: block;
        }
      }
    }

    .banner_content_area {
      position: absolute;
      // top: 0px;
      @media (min-width: 768px) {
        left: 50px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      @media (max-width: 767px) {
        top: 45px;
        left: 0;
        .common_btn {
          display: none;
        }
      }

      .banner_title {
        color: $white;
        font-size: 50px;
        font-weight: bold;
        @media (max-width: 1199px) {
          font-size: 40px;
        }
        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
      .banner_sub_text {
        color: $white;
        font-size: 16px;
        margin: 15px 0;
      }
    }
  }
  @media (max-width: 767px) {
    .slick-next {
      right: -5px;
    }
    .slick-prev {
      left: -5px;
      z-index: 1;
    }
  }
  .slick-dots {
    bottom: 20px !important;

    li {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 20px !important;
      width: 15px !important;
      height: 15px !important;
      button {
        width: 15px;
        height: 15px;
        &::before {
          font-size: 10px;
          line-height: 16px;
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          height: 15px;
          color: white !important;
          opacity: 0.7;
        }
      }
      &.slick-active {
        button::before {
          color: $theme_color_light !important;
        }
      }
    }
  }
}

.banner_games_area {
  margin-top: -100px;
  position: relative;
  z-index: 2;
  padding: 0 95px;
  @media (max-width: 1199px) {
    margin-top: -70px;
    padding: 0 50px;
  }
  @media (max-width: 991px) {
    margin-top: -20px;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
    padding: 15px 0;
    display: none;
  }
  // position: absolute;
  // left: 0px;
  // bottom: -100px;
  .game_img_part {
    padding: 0;
    margin: 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 991px) {
      grid-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 767px) {
      grid-gap: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .single_game {
      .game_footer {
        .game_play_button {
          width: 100%;
          min-width: unset !important;
        }
      }
    }
  }
  .game_name_part {
    height: 50px;
    align-items: center;
    padding: 0;
    margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
    @media (max-width: 991px) {
      grid-gap: 20px;
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 767px) {
      grid-gap: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
    // background-color: #0c1f2d;
    // -webkit-box-shadow: inset 0px 0px 10px 5px #000000;
    // box-shadow: inset 0px 0px 10px 5px #000000;

    -webkit-box-shadow: inset 0px 0px 10px 5px #000000;
    box-shadow: inset 0px 0px 10px 5px #000000;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#04090e+0,0b1f2c+17,0e2636+26,0e2737+31,0e2535+38,061119+76,03080d+100 */
    background: rgb(4, 9, 14); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(4, 9, 14, 1) 0%,
      rgba(11, 31, 44, 1) 17%,
      rgba(14, 38, 54, 1) 26%,
      rgba(14, 39, 55, 1) 31%,
      rgba(14, 37, 53, 1) 38%,
      rgba(6, 17, 25, 1) 76%,
      rgba(3, 8, 13, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(4, 9, 14, 1) 0%,
      rgba(11, 31, 44, 1) 17%,
      rgba(14, 38, 54, 1) 26%,
      rgba(14, 39, 55, 1) 31%,
      rgba(14, 37, 53, 1) 38%,
      rgba(6, 17, 25, 1) 76%,
      rgba(3, 8, 13, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(4, 9, 14, 1) 0%,
      rgba(11, 31, 44, 1) 17%,
      rgba(14, 38, 54, 1) 26%,
      rgba(14, 39, 55, 1) 31%,
      rgba(14, 37, 53, 1) 38%,
      rgba(6, 17, 25, 1) 76%,
      rgba(3, 8, 13, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#04090e', endColorstr='#03080d',GradientType=0 ); /* IE6-9 */

    li {
      color: $white;
      text-align: center;
      a {
        color: $white;
        text-decoration: none;
        &:hover {
          color: $hoveColor;
          text-decoration: none;
        }
      }
    }
  }
}

.faq_container {
  .faq_top_area {
    margin: 30px 0;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media (max-width: 767px) {
      display: block;
    }
    .faq_menu {
      text-align: right;
      @media (max-width: 767px) {
        text-align: center;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          margin-left: 15px;
          a {
            color: $white;
            text-transform: uppercase;
            font-size: 14px;
            text-decoration: none;
            font-weight: 500;
            &:hover,
            &.active {
              color: $theme_color_deep;
            }
          }
        }
      }
    }
  }
  .faq_bottom_area {
  }
}

.access_control_area {
  .chimp-accordion {
    .accordion-header {
      .accordion-button {
        color: $white;
        font-weight: 500;
        border-radius: 0px;
        text-shadow: 1px 1px 1px #000;
        &::after {
          background-image: url("../assets/images/icons/cross-icon.svg");
          background-size: contain;
          background-position: center;
        }
        &.collapsed {
          background: $second_color !important;
          &::after {
            background-image: url("../assets/images/icons/plus-icon.svg");
            background-size: contain;
            background-position: center;
          }
        }
      }
    }
  }
}

.zino_category_menu {
  // padding-top: 20px;

  .common_headline_with_bg {
    margin-top: 30px 0;
  }

  ul {
    margin: 0px auto !important;
    padding: 0px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 991px) {
      grid-gap: 5px;
    }
    @media (max-width: 767px) {
      grid-gap: 0px;
    }
    @media (min-width: 1200px) {
      width: 80%;
    }
    li {
      // display: inline-block;

      text-align: center;
      .categoryMenu {
        // width: 75%;
        margin: 0 auto;
        position: relative;
        @media (max-width: 1199px) {
          width: 100%;
        }
        @media (min-width: 1800px) {
          width: 85%;
        }
      }
      .categoy_icon {
        width: 100%;
      }
      .bottom_text_area {
        text-align: left;
        display: none;
        text-decoration: none;
        width: 100%;
        position: absolute;
        bottom: -30px;
        padding: 0 20px;
        @media (max-width: 767px) {
          bottom: -10px;
        }
        .sub_text {
          color: $white;
        }
        .category_name {
          display: block;
          width: 100%;
          position: relative;
          color: $white;
          font-family: $openSene;
          font-size: 22px;
          line-height: 30px;
          font-weight: bold;
          text-decoration: none;
          margin-top: 5px;
          text-align: center;
          @media (max-width: 1199px) {
            font-size: 18px;
          }
          @media (max-width: 991px) {
            font-size: 16px;
            line-height: 20px;
          }
          // &::after {
          //   content: url("../assets/images/common/angle-right-solid-white.svg");
          //   transform: scale(0.06);
          //   width: 20px;
          //   height: 20px;
          //   position: absolute;
          //   right: 5px;
          //   top: -4px;
          //   @media (max-width: 991px) {
          //     top: -4px;
          //   }

          //   @media (max-width: 767px) {
          //     right: -12px;
          //     top: -7px;
          //   }
          // }
        }
      }
      // background-image: url('../assets/images/common/cagegory_menu_bg.png');

      &:hover {
        .categoy_icon {
          -webkit-filter: brightness(70%);
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
        }

        // .category_name {
        //   &::after {
        //     content: url("../assets/images/common/angle-right-solid-yellow.svg") !important;
        //   }
        // }
      }
    }
  }
}
