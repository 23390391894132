.footer {
  .footer_newsletter {
    padding: 100px 0 0;

    @media (max-width: 575px) {
      padding: 0px 0 0;
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .footer_grids {
    // &.landing {
    //   background: #1d3b6a;
    // }
    .links_section {
      padding: 50px 0;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 1fr;

      @media (max-width: 991px) {
        padding: 60px 0;
      }
      @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 30px 0 15px 0;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        grid-template-columns: repeat(3, 1fr);
        padding: 30px 0 15px 0;
      }

      .single_block {
        @media (max-width: 767px) {
          margin-top: 15px;
        }
        padding: 0 15px;
        &:nth-child(1) {
          padding-left: 0 !important;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            font-size: 13px;
            list-style: none;
            color: $white;
            margin-bottom: 8px;
            &.list_heading {
              margin-bottom: 20px;
              color: $theme_color_deep;
              font-weight: 600;
            }

            a {
              position: relative;
              text-decoration: none;
              color: $white;
              .footer_logo {
                margin-bottom: 15px;
                width: 150px;
              }
              &:hover {
                color: $theme_color_deep;
              }
            }
            .footer_animated_logo {
              width: 160px;
            }

            svg {
              margin-right: 15px;
              margin-top: 5px;
            }
            &.active_social_link {
              ul {
                display: flex;
                gap: 15px;
                .fab {
                  font-size: 26px;
                }
              }
            }
          }
          &.like_left_menu {
            li {
              // border-bottom: 1px solid #000;
              margin-bottom: 0px;
              a {
                display: grid;
                align-items: center;
                grid-template-columns: 24px 1fr;
                // opacity: 0.7;
                padding: 1px 0;
                text-decoration: none;
                &:hover {
                  opacity: 1;
                }
              }
              .icon {
                background: url("../assets/images/common/all_bg.png");
                width: 24px;
                height: 24px;
              }
              .text {
                color: #fff !important;
                padding-left: 5px;
                font-size: 13px;
              }

              &.homeMenu {
                .icon {
                  background-position: -48px -447px;
                }
              }
              &.slotsMenu {
                .icon {
                  background-position: -96px -448px;
                  // background-image: unset;
                }
              }
              &.liveCasinoMenu {
                .icon {
                  background-position: -291px -448px;
                  // background-image: unset;
                }
              }
              &.newGamesMenu {
                .icon {
                  background-position: -315px -380px;
                  // background-image: unset;
                }
              }
              &.casinoGamesMenu {
                .icon {
                  background-position: -240px -447px;
                  // background-image: unset;
                }
              }

              &.jackpotsMenu {
                .icon {
                  background-position: -570px -446px;
                  // background-image: unset;
                }
              }
              &.zinoExclusiveMenu {
                .icon {
                  background-position: -387px -381px;
                }
              }
              &.promotoinsMenu {
                .icon {
                  background-position: -339px -448px;
                  // background-image: unset;
                }
              }
              &.vipClubMenu {
                .icon {
                  background-position: -194px -449px;
                  // background-image: unset;
                }
              }

              &.active,
              &:hover {
                a {
                  opacity: 1;
                }
                .icon {
                  border-radius: 50%;
                  background-color: $theme_color_light;
                }
                .text {
                  color: $theme_color_light;
                }

                &.homeMenu {
                  .icon {
                    background-position: -48px -477px;
                  }
                }
                &.slotsMenu {
                  .icon {
                    background-position: -96px -477px;
                  }
                }
                &.liveCasinoMenu {
                  .icon {
                    background-position: -291px -477px;
                  }
                }
                &.newGamesMenu {
                  .icon {
                    background-position: -315px -410px;
                  }
                }
                &.casinoGamesMenu {
                  .icon {
                    background-position: -241px -477px;
                  }
                }

                &.jackpotsMenu {
                  .icon {
                    background-position: -572px -476px;
                  }
                }
                &.zinoExclusiveMenu {
                  .icon {
                    background-position: -387px -410px;
                  }
                }
                &.promotoinsMenu {
                  .icon {
                    background-position: -339px -477px;
                  }
                }
                &.vipClubMenu {
                  .icon {
                    background-position: -194px -478px;
                  }
                }
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
  .copyright_section {
    margin-bottom: 20px;

    text-align: center;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 175px 8fr;
    }

    padding: 20px 0;
    @media (min-width: 768px) {
      margin-left: -30px;
    }
    .right {
      background: $black_bg;
    }
    .copyright_content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 575px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
      }

      .logo {
        img {
          max-height: 40px;
        }
      }
     
      .social_links {
        display: flex;
        align-items: center;
        gap: 15px;
        li {
          list-style: none;
          font-size: 16px;

          a {
            text-decoration: none;
            color: $white;
            &.inactive {
              color: #929292;
            }
          }
        }
      }
      .other_logos {
        img {
          width: 50px;
          // height: 50px;
          &.percent_secure_img {
          }
          &.provably_fair_img {
            width: 70px;
          }
          &.responsible_gaming_img {
            width: 80px;
          }
          &.plus_age_img {
            width: 50px;
          }
        }
        &.dotIgamingLogo {
          img {
            width: 120px;
          }
          &:hover {
            .hoverImg {
              display: block;
            }
            .solidImg {
              display: none;
            }
          }

          .solidImg {
            display: block;
            opacity: 0.5;
          }
          .hoverImg {
            display: none;
          }
          // img{
          //   width: 150px;
          // }
        }
      }
    }
  }

  .license_and_info_text {
    margin-bottom: 20px;
    // display: grid;
    text-align: center;
    grid-template-columns: 175px 8fr;
    .license_seal {
    }
    .company_info {
      color: $white;
      font-size: 12px;
      font-weight: 300;
      margin: 10px 0;
    }
  }
}
.copyright_text {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 10px;
}

.dotIgamingLogo {
  img {
    width: 120px;
  }
  &:hover {
    .hoverImg {
      display: block;
    }
    .solidImg {
      display: none;
    }
  }

  .solidImg {
    display: block;
    opacity: 0.5;
  }
  .hoverImg {
    display: none;
  }
  // img{
  //   width: 150px;
  // }
}

.bonusBtn {
  .dollerIcons {
    display: block;
    img {
      width: 30px;
      margin-top: -10px;
    }
  }
  .bonusText {
    display: none;
  }

  &:hover {
    .dollerIcons {
      display: none;
    }
    .bonusText {
      display: block;
    }
  }
}

.back_to_top {
  text-align: right;
  font-size: 13px;
  color: $white;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  a {
    height: 50px;
    width: 50px;
    padding-top: 10px;
    text-decoration: none;
    display: block;
    border-radius: 50%;
    color: $black;
    background: $theme_color_light;
    text-align: center;
    font-size: 20px;
  }
}

.newsletter_content {
  .left_content {
    display: flex;
    justify-content: space-between;
    grid-gap: 15px;
    @media (max-width: 575px) {
      flex-direction: column;
      gap: 20px;
      margin-top: 10px;
      align-items: center;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      flex-direction: column;
      gap: 20px;
    }

    .block_title {
      color: $white;
      text-transform: uppercase;
      font-size: 16px;
      .dash {
        margin-left: 40px;
      }
    }
    .block_heading {
      .headline {
        font-size: 36px;
        line-height: 1;
        color: $white;
        margin-bottom: 10px;
        @media (max-width: 575px) {
          font-size: 24px;
        }
      }
    }
    .subscribe_input {
      height: 45px;
      width: 70%;
      padding-left: 30px;
      border-radius: 5px;
      border: none;
      font-weight: 600;

      @media (max-width: 575px) {
        width: 90%;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        width: 100%;
      }
      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
      font-size: 13px;
      // text-transform: uppercase;
      color: #a1a1a1;
    }
  }
}
footer {
  padding-top: 30px;
  .other_logos_area {
    ul {
      margin: 10px 0;
      padding: 0px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      li {
        text-align: center;
        list-style: none;
        margin-bottom: 10px;
        .percent_secure_img {
          width: 80px;
        }
        .provably_fair_img {
          width: 95px;
        }
        .plus_age_img {
          width: 60px;
        }
        .responsible_gaming_img {
          margin-top: 15px;
          width: 120px;
        }

        &.dotIgamingLogo {
          img {
            width: 120px;
            margin: 20px auto 0 auto;
          }
          &:hover {
            .hoverImg {
              display: block;
            }
            .solidImg {
              display: none;
            }
          }

          .solidImg {
            display: block;
          }
          .hoverImg {
            display: none;
          }
          // img{
          //   width: 150px;
          // }
        }
      }
    }
  }

  .footer_menu {
    text-align: center;
    ul {
      padding: 10px 0;
      margin: 0px;
      border-top: 1px solid $theme_color_deep;
      &.last-child {
        border-bottom: 1px solid $theme_color_deep;
        margin-bottom: 20px;
      }
      @media (max-width: 575px) {
        max-width: 173px;
        margin: 0 auto;
        list-style: none;
      }
      li {
        a {
          text-decoration: none;
          color: $white;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .casino_license_area {
    text-align: center;
    .license_logo {
    }
    .licese_text {
      color: $white;
      margin-top: 10px;
    }
  }
  .footer_logo_area {
    text-align: center;
    video {
      width: 150px;
    }

    padding: 15px 0;
  }
}

.sticky_footer_menu_area {
  z-index: 100;
  position: sticky;
  bottom: -1px;
  .sticky_search_area {
    // border: 1px solid $first_color;
    background-color: $bg_color;
    height: 40px;
    padding: 5px 10px;
    display: none;
    transition: all 3s;
    input {
      border-radius: 8px;
      background: $input_bg_color;
      color: $white;
      height: 30px;
      // transition: visibility 0s linear 700ms, opacity 700ms;

      font-family: $font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      width: 100%;
      padding: 0 10px;
      border: none;
      &:focus {
        border: none;
        outline: none;
      }
    }
    &.showSearch {
      display: block;
    }
  }
  ul {
    border-top: 1px solid $main_color;
    background-color: rgba(6, 6, 14, 0.9);
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    li {
      padding: 5px 0 15px 0;
      list-style: none;
      color: $white;
      // line-height: 40px;
      a {
        color: $white;
        .svg_icon {
          fill: $white;
        }
        .menu_text {
          font-size: 11px;
          font-family: $font;
          line-height: 10px;
        }
        &:hover {
          color: $main_color;
          text-decoration: none;
        }
      }
      &:hover {
        color: $main_color;
      }
    }
  }
}

.brands {
  // padding-top: 35px;
  ul {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 16px 12px;
    margin-bottom: 10px !important;
    li {
      margin: 10px;
      list-style: none;
      a {
        opacity: 1;

        &:hover {
          opacity: 1;
        }
      }
      .brand_img {
        height: 30px;
        width: auto;
      }
      &.dotIgamingLogo {
        &:hover {
          .hoverImg {
            display: block;
          }
          .solidImg {
            display: none;
          }
        }

        .solidImg {
          display: block;
        }
        .hoverImg {
          display: none;
        }
        // img{
        //   width: 150px;
        // }
      }
    }
  }
}

.present_area {
  bottom: 50px;
  // left: 100%;
  right: 100px;
  z-index: 1000;
  position: fixed;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  border: 1px solid $theme_color_deep;
  background-color: #000;
  img {
    width: 30px;
    margin: 8px auto 0 auto;
  }
  -webkit-animation: greenBtn 1.2s infinite;
  animation: greenBtn 1.2s infinite;

  .greenIcon {
    display: block;
  }
  .blueIcon {
    display: none;
  }
  &:hover {
    .greenIcon {
      display: none;
    }
    .blueIcon {
      display: block;
    }
    border: 1px solid $orenge;
    -webkit-animation: blueBtn 1.2s infinite;
    animation: blueBtn 1.2s infinite;
  }

  @media (max-width: 767px) {
    bottom: 70px;
    // left: 100%;
    right: 20px;
  }
}
