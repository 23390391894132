.pagination {
  .page-item {
    .page-link {
      text-decoration: none !important;
      text-transform: capitalize;
      font-weight: 700;
      font-size: 14px !important;
      display: block;
      color: $black !important;
      width: fit-content;
      white-space: nowrap;
      text-align: center;
      margin: 0 auto;
      height: 40px;
      background: $dark_white;
      padding: 0 15px !important;
      border: none;
      outline: 0;
      transition: all 0.3s ease-in-out;
      outline: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:hover,
      &.current-page {
        color: black !important;
        background-image: linear-gradient(
          to right,
          $dark_white,
          $dark_white 50%,
          $theme_color_light 50%
        );
        background-size: 200% 100%;
        background-position: -100%;
        border-color: transparent;
      }
    }
  }
}

