.about {
  .vip_banner {
    border: 2px solid rgba(112, 112, 119, 0.6);
    border-radius: 5px;
    margin-bottom: 70px;
    position: relative;
    @media (max-width: 575px) {
      margin-bottom: 30px;
    }
    img {
      border-radius: 5px;
      width: 100%;
      @media (max-width: 575px) {
        min-height: 150px;
      }
    }
    .banner_text {
      position: absolute;
      left: 70px;
      top: 70px;

      &.promotions_text {
        width: 100%;
        top: 25px !important;
        left: 0 !important;
        @media (min-width: 768px) and (max-width: 991px) {
          top: 10px !important;
        }
      }
      @media (max-width: 575px) {
        top: 15px;
        left: 15px;
      }

      @media (min-width: 576px) and (max-width: 767px) {
        top: 15px;
        left: 15px;
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        top: 25px;
        left: 25px;
      }
      .banner_headline {
        font-size: 50px;
        color: $white;
        &.small {
          font-size: 30px;
        }
        img {
          max-width: 400px;
          border-radius: 13px;
        }
        @media (max-width: 575px) {
          font-size: 30px;
          line-height: 1;
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          line-height: 1.2;
        }
      }
    }
  }
  .vip_area {
    .vip_top_section {
      ul {
        margin: 0px;
        padding: 0px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        @media (max-width: 575px) {
          grid-template-columns: 1fr;
          grid-gap: 0px;
        }
        li {
          list-style: none;
          text-align: center;
          .vipIcon {
            width: 40px;
            border-radius: 3px;
            // box-shadow: 1px 1px 1px #000;
          }
        }
      }
    }

    .vip_accordion_area {
      .accordion {
        .accordion-item {
          color: #fff;
          background-color: #464c4e;
          margin-bottom: 20px;
          .accordion-header {
            .accordion-button {
              color: #fff;
              background-color: #464c4e;
              .vipAccordionIcon {
                width: 20px;
                margin-right: 10px;
              }
              &:focus {
                outline: 0;
                box-shadow: none;
              }
            }
          }
          .accordion-body {
            border-top: 1px solid #191919;
          }
        }
      }
    }
    .sub_heading {
      color: $white;
      font-size: 16px;
      font-weight: bold;
      margin: 15px 0;
    }
    .paragraph {
      color: $white;
      font-size: 14px !important;
      font-weight: 400;
    }
  }
  .privacy_policy {
    // display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px 15px;
    @media (min-width: 1200px) {
      grid-gap: 30px 30px;
    }

    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    .terms_content {
      background-color: #191b1d;
      background-position: top;
      border-radius: 7px;
      padding: 20px;

      @media (min-width: 1200px) {
        padding: 60px 60px 45px;
      }

      &.benefit_content {
        background-image: url("../assets/images/vip/benefit-bg.png");
        background-repeat: no-repeat;
        background-position: bottom;
      }
      .benifit_menu {
        padding: 15px !important;
        margin: 0 !important;
        li {
          &::marker {
            color: $theme_color_deep !important;
          }
        }
      }
      .page_title {
        font-size: 22px;
        color: $white;
        margin-bottom: 15px;
        line-height: 1;
        min-height: 22px;
        text-transform: uppercase;
        @media (max-width: 575px) {
          font-size: 18px;
        }
      }
      .paragraph {
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .promotions_section {
    .promotions_list {
      border: 1px solid $theme_color_deep;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 100px 50px;
      @media (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 80px;
      }
      .single_promotion {
        padding: 15px;

        .promotion_text_and_icon {
          display: grid;
          align-items: center;
          grid-template-columns: 2fr 1fr;
          @media (max-width: 575px) {
            display: flex;
            flex-direction: column-reverse;
          }

          .text_section {
            .title {
              font-size: 20px;
              font-weight: 600;
              color: $theme_color_deep;
            }
            .headline {
              font-size: 25px;
              font-weight: 600;
              color: $white;
            }
            .paragraph {
              line-height: 1.4 !important;
            }
          }
          .icon_section {
            text-align: center;
            img {
              max-width: 100px;
            }
          }
          a{
            color: blue;
          }
        }
        .step_and_btns {
          max-height: 115px;
          font-size: 140px;
          text-transform: uppercase;
          color: #323233;
          font-weight: 600;
          line-height: 1;
          position: relative;
          width: max-content;
          margin-bottom: 20px;

          @media (max-width: 575px) {
            font-size: 80px !important;
          }
          .deposit_btn {
            position: absolute;
            top: 24px;
            left: 10px;
            @media (max-width: 575px) {
              top: 10px;
              left: 0px;
              height: 40px !important;
              line-height: 40px !important;
            }
          }
          .deposit_text {
            font-size: 34px;
            color: $white;
            text-transform: uppercase;
            letter-spacing: 20px;
            position: absolute;
            text-align: center;
            left: 50%;
            bottom: -20px;
            transform: translate(-50%);
            @media (max-width: 575px) {
              font-size: 25px;
              letter-spacing: 15px;
              bottom: -10px;
            }
          }
        }
      }
    }

    // .promotion_banner_bottom {
    //   margin-top: 150px;
    //   position: relative;
    //   border-radius: 0 5px 5px 0;
    //   @media (max-width: 991px) {
    //     background: $theme_color_deep;
    //     border-radius: 5px;
    //     margin-top: 50px;
    //   }

    //   .bonus_banner_img {
    //     width: 100%;
    //     border-radius: 0 5px 5px 0;
    //     &.reverse_img {
    //       border-radius: 5px 0 0 5px;
    //     }
    //     // @media (min-width: 992px) and (max-width: 1199px) {
    //     //   height: 300px !important;
    //     // }
    //     // @media (min-width: 1200px) {
    //     //   height: 355px !important;
    //     // }
    //   }

    //   .theme_bg_overlap {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background: #7b66dd;
    //     width: 100px;
    //     height: 100%;
    //     &.reverse_area {
    //       right: 0 !important;
    //       top: 0 !important;
    //       left: unset;
    //     }
    //   }
    //   .clip_area {
    //     padding: 20px 0;
    //     bottom: 0;
    //     left: 0;
    //     position: absolute;
    //     height: 100%;
    //     width: 59%;
    //     background: $black_bg;
    //     clip-path: polygon(10% -1%, 100% 0, 85% 100%, 73% 101%, 0 100%, 0 41%);
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-around;
    //     align-items: center;

    //     &.reverse_clip_area {
    //       position: absolute;
    //       right: 0 !important;
    //       bottom: 0 !important;
    //       left: unset !important;
    //       height: 100% !important;
    //       clip-path: polygon(
    //         0 0,
    //         87% -10%,
    //         100% 41%,
    //         100% 101%,
    //         25% 120%,
    //         9% 41%
    //       );
    //       @media (max-width: 991px) {
    //         position: unset;
    //         width: 100%;
    //       }
    //     }

    //     @media (max-width: 991px) {
    //       position: unset;
    //       width: 100%;
    //     }
    //     .bonus_heading {
    //       font-size: 55px;
    //       text-transform: uppercase;
    //       text-align: center;
    //       line-height: 1;
    //       color: $white;
    //       @media (max-width: 1199px) {
    //         font-size: 40px;
    //         padding: 0 10px;
    //       }
    //     }
    //     .bonus_sub_headline {
    //       font-size: 20px;
    //       text-align: center;
    //       color: $white;

    //       @media (max-width: 1199px) {
    //         font-size: 17px;
    //         margin: 10px 0;
    //       }
    //     }
    //     .custom_for_banner {
    //       padding: 0 40px;
    //     }
    //   }
    //   .charactar {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     img {
    //       width: 100%;
    //       height: 380px;
    //       @media (min-width: 992px) and (max-width: 1199px) {
    //         height: 280px;
    //       }
    //     }

    //     &.charactar_reverse {
    //       left: 10%;
    //       bottom: 0;
    //       right: unset;
    //     }
    //   }
    // }
    // .promotion_banner_bottom {
    //   margin-top: 180px;
    //   position: relative;
    //   .banner_bg_img {
    //     img {
    //       width: 100%;
    //       border-radius: 0 5px 5px 0;
    //     }
    //   }
    //   .text_section {
    //     position: absolute;
    //     top: 0;
    //     background: $black_bg;
    //     height: 100%;
    //     width: 59%;
    //     -webkit-clip-path: polygon(
    //       10% -1%,
    //       100% 0,
    //       85% 100%,
    //       73% 101%,
    //       0 100%,
    //       0 41%
    //     );
    //     clip-path: polygon(10% -1%, 100% 0, 85% 100%, 73% 101%, 0 100%, 0 41%);
    //   }

    // }
  }
}

.promotion_banner_bottom {
  margin-top: 150px;
  position: relative;
  border-radius: 0 5px 5px 0;
  @media (max-width: 991px) {
    background: $theme_color_deep;
    border-radius: 5px;
    margin-top: 50px;
  }

  .bonus_banner_img {
    width: 100%;
    border-radius: 0 5px 5px 0;
    &.reverse_img {
      border-radius: 5px 0 0 5px;
    }
    // @media (min-width: 992px) and (max-width: 1199px) {
    //   height: 300px !important;
    // }
    // @media (min-width: 1200px) {
    //   height: 355px !important;
    // }
  }

  .theme_bg_overlap {
    position: absolute;
    top: 0;
    left: 0;
    background: $theme_color_light;
    width: 100px;
    height: 95%;
    &.reverse_area {
      right: 0 !important;
      top: 0 !important;
      left: unset;
    }
  }
  .clip_area {
    padding: 20px 0;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 59%;
    background: $black_bg;
    clip-path: polygon(10% -1%, 100% 0, 85% 100%, 73% 101%, 0 100%, 0 41%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &.reverse_clip_area {
      position: absolute;
      right: 0 !important;
      bottom: 0 !important;
      left: unset !important;
      height: 100% !important;
      clip-path: polygon(0 0, 87% -10%, 100% 41%, 100% 101%, 25% 120%, 9% 41%);
      @media (max-width: 991px) {
        position: unset;
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      position: unset;
      width: 100%;
    }
    .bonus_heading {
      font-size: 55px;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      color: $white;
      @media (max-width: 1199px) {
        font-size: 40px;
        padding: 0 10px;
      }
    }
    .bonus_sub_headline {
      font-size: 20px;
      text-align: center;
      color: $white;

      @media (max-width: 1199px) {
        font-size: 17px;
        margin: 10px 0;
      }
    }
    .custom_for_banner {
      padding: 0 40px;
    }
  }
  .charactar {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 380px;
      @media (min-width: 992px) and (max-width: 1199px) {
        height: 280px;
      }
    }

    &.charactar_reverse {
      left: 10%;
      bottom: 0;
      right: unset;
    }
  }
  &.refer_a_friend_promotion {
    .custom_for_banner {
      padding: 0 80px;
    }
  }
}

.promotions_section {
  h1 {
    font-size: 80px;
    color: #fff;
    text-align: center;
  }
  .paragraph {
    font-size: 20px;
  }
}
