$reg_base_color: #1daa63;
$reg_base_deep_color: #1daa63;

$input_normal_border_color: rgba(0, 0, 0, 0.38);
$input_normal_focused_border_color: #add8e6;

$input_error_border_color: #e60000;
$input_error_focused_border_color: #e60000;

$input_accept_border_color: #1daa63;
$input_accept_focused_border_color: #1daa63;

$placeholder_text_color: rgba(0, 0, 0, 0.54);
$text_color: #fff;

.reg_bouns_area {
    padding-top: 15px;
  .reg_bonus_heading {
    font-size: 14px;
    margin-bottom: 10px;
    color: $text_color;
  }
  ul {
    padding: 0px;
    margin: 0px;
    li {
      display: inherit;
      position: relative;
      margin-bottom: 20px;
      .reg_bonus_title {
        margin-bottom: 0px;
        color: #bebedb;
        font-size: 14px;
        font-weight: 600;
      }

      .reg_bonus_radio_area {
        width: 100%;
        .reg_bonus_banner {
          max-height: 0;
          overflow-y: hidden;
          -webkit-transition: max-height 0.5s ease-in-out;
          -moz-transition: max-height 0.5s ease-in-out;
          -o-transition: max-height 0.5s ease-in-out;
          transition: max-height 0.5s ease-in-out;
          img {
            width: 100%;
          }
        }
        .radio_area {
          width: 100%;
          cursor: pointer;
          .radio_check_and_text_area {
            padding: 0 15px;
            margin-top: 10px;
          }
          .radio_box {
            vertical-align: sub;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            display: inline-block;
            border: 2px solid #a9a9a9;
            border-radius: 25px;
            text-align: center;
            .radio_checked_box {
              width: 10px;
              height: 10px;
              border-radius: 25px;
              margin: 3px auto 0 auto;
              display: none;
            }
          }
          .radio_text {
            display: inline-block;
            font-weight: 600;
            width: calc(100% - 35px);
            vertical-align: unset;
            color: $text_color;
            line-height: 18px;
          }
        }
        input[type="radio"] {
          display: none;
          &:checked + label {
            .radio_box {
              border: 2px solid $reg_base_color;
            }
            .radio_checked_box {
              display: block;
            }
            .reg_bonus_banner {
              max-height: 72px;
            }
          }
        }
      }
      .reg_bonus_short_text {
        padding: 10px 15px;
        color: $text_color;
      }
      .reg_bonus_link_area {
        max-height: 0;
        padding: 0px 15px;
        overflow: hidden;
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
        a {
          display: block;
          color: $reg_base_color;
          &:hover {
            text-decoration: none;
          }
        }
        &.expanded {
          max-height: 100px !important;
        }
      }
      .reg_bonus_more_less_area {
        padding: 0 15px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        .more_less {
          color: $reg_base_color;
          &:hover {
            text-decoration: none;
          }
          .btn_text {
          }
          .btn_icon {
            margin-left: 10px;
          }
        }
      }

      &.no_bonus_radio {
        box-shadow: none;
      }

      .single_input {
        margin: 10px 15px;
        position: relative;
        .bonus_reg_input {
          width: 100%;
          height: 48px;
          border-radius: 6px;
          position: relative;
          display: block;
          line-height: 19px;
          padding: 20px 12px 10px 12px;
          color: rgba(0, 0, 0, 0.87);
          background-color: #fff;
          font-size: 16px;
          font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
          border: 1px solid $input_normal_border_color;
          -webkit-transition: border 0.3s ease-in-out,
            -webkit-box-shadow 0.3s ease-in-out;
          transition: border 0.3s ease-in-out,
            -webkit-box-shadow 0.3s ease-in-out;
          transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
          transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out,
            -webkit-box-shadow 0.3s ease-in-out;
          &:focus {
            outline: none;
            border-color: $input_normal_focused_border_color;
            -webkit-box-shadow: 0 0 5px $input_normal_focused_border_color;
            box-shadow: 0 0 5px $input_normal_focused_border_color;
          }
        }

        .placeholder_text {
          color: $placeholder_text_color;
          font-size: 16px;
          line-height: 40px;
          position: absolute;
          top: 5px;
          left: 12px;
          z-index: 20;
          overflow: hidden;
          max-width: calc(100% - 20px);
          pointer-events: none;
          white-space: nowrap;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          -webkit-transition: font-size 0.2s ease-in-out,
            line-height 0.2s ease-in-out;
          transition: font-size 0.2s ease-in-out, line-height 0.2s ease-in-out;
          &.input_focused {
            font-size: 12px;
            line-height: 10px;
          }
        }
        .error_text {
          display: none;
          font-size: 12px;
          line-height: 16px;
          white-space: normal;
          word-break: break-word;
          clear: both;
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
          color: $input_error_border_color;
          margin: 4px 12px 8px;
        }
        .input_info {
          font-size: 12px;
          line-height: 16px;
          white-space: normal;
          word-break: break-word;
          clear: both;
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
          color: rgba(0, 0, 0, 0.54);
          margin: 4px 12px 8px;
        }

        .fa-check {
          color: $input_accept_border_color;
          position: absolute;
          top: 8px;
          right: 10px;
          font-size: 12px;
          display: none;
        }

        &.error_input {
          .bonus_reg_input {
            outline: none;
            border-color: $input_error_border_color;
            -webkit-box-shadow: 0 0 5px $input_error_border_color;
            box-shadow: 0 0 5px $input_error_border_color;
            &.passwordInput {
              border-bottom: 5px solid $input_error_border_color;
            }
          }
          .input_info {
            display: none;
          }
          .error_text {
            display: block;
          }
        }
        &.accept_input {
          .bonus_reg_input {
            border-color: $input_accept_border_color;
            &.passwordInput {
              border-bottom: 5px solid $input_accept_border_color;
            }
          }
          .fa-check {
            display: block;
          }
        }
      }
    }
  }
}
