.content_page {
  &.vip_page {
    padding-top: 30px;
    .common_headline_with_bg {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 30px;
      font-family: $myriadPro;
      line-height: 50px;
      margin-top: 10px;
    }
    .cheap_price {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      li {
        // grid-gap: 30px;
        margin-bottom: 20px;
        color: $white;
        .check_mark {
          margin-right: 10px;
          width: 25px;
          vertical-align: top;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0px 0 !important;
  }
}

.content_section {
  @media (max-width: 767px) {
    padding: 0px 0 !important;
  }
}
