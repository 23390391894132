
.my_tickets_area {
  margin-top: 20px;
  .header {
    font-size: 20px;
    // text-align: center;
    // font-weight: bold;
    color: $white;
    margin-bottom: 20px;
  }
  .accordion .accordion-header .accordion-button {
    a {
      color: $white;
      text-decoration: none;
    }
  }
  .accordion .accordion-header .accordion-button:not(.collapsed) {
    a {
      color: $white;
    }
  }
  .faq__answer .accordion-body{
    color: #fff;
  }
  .single_ticket{
    color: #fff;
  }
  .chimp-accordion .accordion-body{
    color: #fff;
  }
}

.ticket_details_container {
  .common_title {
    color: $white;
  }
  .tickets_list {
    color: $white;
  }
  .conversation_reply_area {
    padding-top: 10px;
  }
}

.tickets_list {
  padding-left: 0px;

  list-style: none;
  .single_ticket {
    /*margin-bottom: 20px;*/
    position: relative;
    a {
      color: #000;
    }
    .ticket_heading {
      display: inline-block;
      margin: 0px 0 20px 0;
      width: calc(100% - 168px);
      font-size: 18px;
      @media (max-width: 767px) {
        width: calc(100% - 54px);
      }
    }
    .ticket_date {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 168px;
      font-size: 14px;
      font-weight: 400;
      text-align: right;
      color: #ccc;
      @media (max-width: 767px) {
        width: 54px;
      }
    }
    .ticket_closed {
      position: absolute;
      right: 0px;
      bottom: 0px;
      color: #d0152d;
    }
    .ticket_status {
      position: absolute;
      right: 0px;
      bottom: 0px;
      display: block;
      background-image: linear-gradient(to bottom, #d0152d 0%, #d0152d 100%);
      font-weight: bold;
      padding: 4px 10px;
      border-radius: 3px;
      color: #fff;
    }
    .conversation_list {
      padding-left: 20px;
      list-style: none;
      li {
        margin-bottom: 25px;
        padding-left: 10px;
        border-left: 3px solid $theme_color_deep;
        position: relative;
        .reply_text {
          display: inline-block;
          /*                        margin: 0px 0px 20px 0px;*/
          text-transform: capitalize;
          width: calc(100% - 168px);
          font-size: 16px;
        }
        .reply_date {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 168px;
          font-size: 14px;
          font-weight: 400;
          text-align: right;
          color: #ccc;
        }
      }
    }
    .conversation_reply_area {
    }
  }
}