header {
  position: sticky !important;
  top: 0;
  z-index: 21;
  background: #000;
}
.invisible_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.main_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.header {
  padding: 10px 0 10px 0;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    padding: 5px 0 0px 0;
  }

  .expand_searchbox {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    z-index: 11111011;

    .expand_input {
      width: 100%;
      height: 100%;
      background: #363637;
      border: 0px;
      padding: 8px 15px;
      border-radius: 3px;
      outline: 0px;
      color: #657b83;
      font-size: 20px;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
    .close_search_bar {
      position: absolute;
      right: 15px;
      top: 30px;
      cursor: pointer;
      color: $theme_color_deep;
      opacity: 1;
      height: 30px;
      width: 30px;
      background: #363637;
      z-index: 2;
      font-size: 25px;
      text-align: center;
    }
  }

  .header_menu {
    padding: 0;
    &.desktop {
      display: grid;
      align-items: stretch;
      @media (max-width: 767px) {
        display: none;
      }
    }
    &.mobile {
      @media (min-width: 768px) {
        display: none;
      }

      .header_logo {
        img {
          // max-width: 40px;
          width: 120px;
          margin-top: 0;
        }
      }

      .mobile_header_right {
        display: flex;
        justify-content: flex-end;
      }

      .LogInBtn {
        height: 35px !important;
        line-height: 35px !important;
        padding: 0 20px !important;
        margin: 0;
        &.only_border {
          &::before {
            height: 46px;
            top: -0.5em;
          }
        }
      }

      .userNameBtn {
        display: flex;
        border-radius: 25px;
        height: 30px;
        width: 30px;
        text-align: center;
        background: $black_bg;
        border-radius: 4px;
        text-decoration: none;
        color: $white;
        font-style: normal;
        font-weight: bold;
        font-size: 18px !important;
        line-height: 30px;
        padding: 0;
        padding-top: 8px;
      }
      .hamburger_menu {
        color: white;
        margin-top: 8px;
        display: block;
      }
      .logo_container {
        padding: 0;
        text-align: center;
      }
      .deposit_amount {
        font-size: 13px;
      }
      .currency_icon {
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .wallet_caret {
        padding-left: 3px;
      }
    }
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 2fr 3fr 3fr 3fr;

    margin: 0;
    position: relative;
    z-index: 1;

    .left_bar {
      a {
        text-decoration: none;
        color: #96a8af;
        font-size: 20px;
        padding-right: 15px;
      }
    }
    .mobile_search_icon {
      @media (max-width: 500px) {
        display: block !important;
        color: #96a8af;
        cursor: pointer;
        font-size: 20px;
      }
    }

    li {
      list-style: none;
      &.left_bar {
        text-align: center;
        max-width: 50px;
        margin-top: 15px;
      }
    }

    .header_logo {
      // text-align: center;
      img {
        width: 60px;
        margin-top: 10px;
      }
      video {
        width: 160px;
        @media (max-width: 767px) {
          width: 100px;
        }
      }
    }

    .header_search {
      .searchbox {
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .user_area {
      // padding-left: 10px;
      // padding-right: 15px;
      // margin-top: 11px;
      ul {
        margin: 10px 0;
        display: flex;
        gap: 20px;
        align-items: center;
        // align-content: flex-end;
        justify-content: end;

        @media (min-width: 320px) and (max-width: 767px) {
          gap: 10px;
        }
        li {
          list-style: none;
          position: relative;
          a {
            font-size: 16px;
            text-decoration: none;
            color: #96a8af;
            .notification {
              font-size: 25px;
            }
            .count {
              position: absolute;
              top: -10px;
              left: 8px;
              display: block;
              background-color: $theme_color_light !important;
            }
            .fa-comments {
              font-size: 20px;
            }
          }

          .userNameBtn {
            transition: all 500ms;
            // background: #304a71;
            border-radius: 4px;
            text-decoration: none;
            color: $white;
            font-style: normal;
            font-weight: bold;
            font-size: 16px !important;
            line-height: 40px;
            text-transform: capitalize;
            display: block;
            text-align: center;
            padding: 0 15px;
            display: grid;
            grid-template-columns: 12px 1fr;
            grid-gap: 10px;
            align-items: baseline;
            // box-shadow: 0px 3px 0px #5e2d94;

            &:hover {
              color: $theme_color_light !important;
              // background: #cfc36e;
            }
          }

          .user_avatar {
            .user_img {
              height: 56px;
              width: 56px;
              border-radius: 50%;
              border: 3px solid #757575;
              @media (max-width: 991px) {
                height: 46px;
                width: 46px;
              }
            }
          }
          .deposit_block {
            padding: 0px;
            .deposit_button {
              border-radius: 0 5px 5px 0;
              .deposit_btn {
                line-height: 40px;
                display: block;
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        .LogInBtn {
          padding: 0 !important;
          width: 70px !important;
          height: 40px !important;
          line-height: 40px !important;
          margin-top: -3px;
          &::before {
            height: 52px;
            top: -0.5em;
          }
        }
        .signUpBtn {
          display: none;
        }
      }
    }
  }
  .small_menu {
    margin-top: 5px;
    padding: 0px;
    margin: 0px;
    display: flex;
    gap: 20px;
    align-items: center;
    // align-content: flex-end;
    justify-content: end;

    @media (min-width: 320px) and (max-width: 767px) {
      gap: 10px;
    }
    @media (max-width: 767px) {
      display: none !important;
    }

    &.mobile_version {
      @media (max-width: 767px) {
        display: flex !important;
        justify-content: center;
      }
      @media (min-width: 768px) {
        display: none !important;
      }
    }

    li {
      list-style: none;
      position: relative;
      a {
        font-size: 16px;
        text-decoration: none;
        color: #96a8af;
        .notification {
          font-size: 25px;
        }
        .count {
          position: absolute;
          top: -10px;
          left: 8px;
          display: block;
          background-color: $theme_color_light !important;
        }
      }

      .userNameBtn {
        transition: all 500ms;
        background: #304a71;
        border-radius: 4px;
        text-decoration: none;
        color: $white;
        font-style: normal;
        font-weight: bold;
        font-size: 12px !important;
        line-height: 24px;
        text-transform: capitalize;
        display: block;
        text-align: center;
        padding: 0 15px;

        // box-shadow: 0px 3px 0px #5e2d94;

        &:hover {
          color: $white !important;
        }
      }

      .user_avatar {
        .user_img {
          height: 56px;
          width: 56px;
          border-radius: 50%;
          border: 3px solid #757575;
          @media (max-width: 991px) {
            height: 46px;
            width: 46px;
          }
        }
      }
    }
  }
}

// .user_menu {
//   position: absolute;
//   background: $white;
//   border-radius: 3px;
//   box-shadow: rgba(2, 37, 96, 0.23) 0px 0px 27px 0px;
//   min-width: 245px;
//   max-width: 245px;
//   padding: 10px 0px;
//   right: 0;
//   top: 45px;
//   .caret_top {
//     font-size: 25px;
//     color: $white;
//     position: absolute;
//     top: -16px;
//     right: 30px;
//   }

//   ul {
//     margin: 0;
//     padding: 0;
//     li {
//       list-style: none;

//       &.with_border {
//         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//       }
//       a {
//         padding: 10px 8px 10px 15px;
//         text-decoration: none;
//         text-transform: uppercase;
//         font-size: 0.75em;
//         display: block;
//         font-weight: 400;
//         width: 100%;
//         color: rgb(52, 53, 58);
//         font-weight: 600;

//         .user_menu_header {
//           display: flex;
//           align-items: center;

//           cursor: pointer;

//           .progress_round {
//             height: 35px;
//             width: 35px;
//             border-radius: 50%;
//             background-image: linear-gradient(
//               to top right,
//               #00629b,
//               #1ca287
//             );
//             border: 3px solid rgba(255, 255, 255, 0.5);
//             font-size: 10px;
//             text-align: center;
//             justify-content: center;
//             font-weight: 600;
//             align-items: center;
//             font-weight: 600;
//             display: flex;
//             margin-right: 10px;
//             color: $white;
//           }
//           .email_address {
//             display: block;
//             font-size: 14px;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             color: rgb(39, 58, 91);
//             font-weight: 400;
//             text-transform: lowercase;
//           }
//         }
//       }
//       &:hover {
//         background: rgba(0, 0, 0, 0.1);
//       }
//     }
//   }
// }

// .user_action_btn {
//   display: flex;
//   background: none;
//   align-items: center;
//   padding: 0;
//   margin: 0;
//   border: none;
//   &:focus {
//     background: none;
//     outline: 0 !important;
//     border: 0 !important;
//     box-shadow: none !important;
//   }

//   .user_name_section {
//     margin-right: 5px;
//     .name {
//       text-align: right;
//       color: rgb(77, 166, 234);
//       font-size: 14px;
//       line-height: 18px;
//       text-transform: lowercase;
//       font-weight: 400;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 80px;
//       &.balance {
//         color: $white;
//         margin: 0;
//       }
//     }
//   }
// }

//left sidebar style
.left_sidebar_menu {
  padding: 40px 20px;
  ul {
    margin: 0;
    padding: 0;
    li {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      padding: 10px 0px;
      position: relative;
      list-style: none;
      a {
        text-decoration: none;
        color: $white;
      }
      .social_menus {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-self: center;
        grid-template-rows: 1fr;
        li {
          list-style: none;
          margin-bottom: 15px;
          a {
            border: 1px solid rgba(255, 255, 255, 0.1);
            text-align: center;
            height: 34px;
            width: 34px;
            border-radius: 50%;
            text-decoration: none;
            color: $common_text_color;
            display: block;
            line-height: 30px;

            svg {
              fill: rgb(162, 167, 194);
            }
            &.facebook:hover {
              background: rgb(73, 103, 173);
              svg {
                fill: $white;
                opacity: 1;
              }
            }
            &.twitter:hover {
              background: rgb(73, 160, 236);
              svg {
                fill: $white;
                opacity: 1;
              }
            }
            &.youtube:hover {
              background: rgb(255, 0, 0);
              svg {
                fill: $white;
                opacity: 1;
              }
            }
            &.instagram:hover {
              background: linear-gradient(
                45deg,
                rgb(64, 93, 230),
                rgb(88, 81, 219),
                rgb(131, 58, 180),
                rgb(193, 53, 132),
                rgb(225, 48, 108),
                rgb(253, 29, 29)
              );
              svg {
                fill: $white;
                opacity: 1;
              }
            }

            &.discord:hover {
              background: rgb(88, 101, 242);
              svg {
                fill: $white;
                opacity: 1;
              }
            }
            &.reddit {
              svg {
                .st0,
                .st1 {
                  display: none;
                  fill: #1f2541;
                }
                .st1 {
                  opacity: 0.1;
                  fill: #fff;
                }
              }
              &:hover {
                background: rgb(255, 69, 0);
                svg {
                  fill: $white;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      &.copyright_text {
        color: $common_text_color;
        text-transform: unset;
        font-weight: 400;
        font-size: 14px;
      }
      &.casino_time {
        text-transform: unset;
        .green {
          color: $green;
          font-weight: 400;
          font-size: 14px;
        }
        .white {
          font-weight: 400;
          font-size: 14px;
          color: $white;
        }
      }
    }
    .social_links {
      background-color: red;
    }
    hr {
      margin: 25px auto;
      border-width: 1px 0px 0px;
      border-right-style: initial;
      border-bottom-style: initial;
      border-left-style: initial;
      border-right-color: initial;
      border-bottom-color: initial;
      border-left-color: initial;
      border-image: initial;
      border-top-style: dotted;
      border-top-color: rgba(255, 255, 255, 0.6);
    }
  }
}

// notification sidebar style
.notification_right_bar {
  .notification_bar_header {
    background-image: linear-gradient(to right, #8a7bfe, #5a4cce);
    display: flex;
    position: sticky;
    z-index: 10;
    top: 0;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    transform: translate3d(0, 0, 0);
    padding: 0 15px;
    &.bg_white {
      background: $white;
      border-bottom: 1px solid #e8e8e8;
      transition: all 0.6s ease;
    }
    .outlink {
      font-size: 18px;
      a {
        text-decoration: none;
        color: $white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .actions {
      display: flex;
      .searc_icon {
        cursor: pointer;
        margin-right: 5px;
        svg {
          fill: $white;
        }
      }
      .close_icon {
        cursor: pointer;
        margin-left: 5px;
        svg {
          fill: $white;
        }
      }
    }
    .back_icon {
      cursor: pointer;
      &:hover {
        svg {
          fill: rgba(0, 0, 0, 0.9);
        }
      }
      svg {
        transform: rotate(180deg);
        fill: rgba(0, 0, 0, 0.5);
      }
    }
    .searchbox {
      width: 100%;
      input {
        border: 0;
        font-size: 18px;
        &:focus {
          outline: 0 !important;
          border: 0 !important;
          box-shadow: none !important;
        }
      }
    }
    .clear_input {
      cursor: pointer;
      &:hover {
        svg {
          fill: rgba(0, 0, 0, 0.9);
        }
      }
      svg {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .notifications_list {
    padding: 10px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        background: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 2px;
        padding: 15px;
        margin: 15px 0 15px;

        &.filter_item {
          box-shadow: none !important;
          text-transform: capitalize;
          font-size: 18px;
          line-height: 18px;
          padding: 0;
          margin: 0;
          transition: all 0.6s ease;
          &.border_one {
            border-top: 1px solid #e8e8e8;
            margin-top: 15px;
            font-size: 12px;
            padding-top: 10px;
            color: #8da2b5;
            cursor: pointer;
            &:hover {
              a {
                color: #4b63af;
              }
            }
            a {
              display: inline;
              text-decoration: none;
              color: #8da2b5;
              padding: 0;
            }
          }
          a {
            text-decoration: none;
            color: #4b63af;
            margin: 10px 0;
            padding: 10px;
            display: block;
            width: 100%;
            &:hover {
              background: rgba(150, 150, 150, 0.1);
            }
          }
          .dot {
            font-size: 8px;
            margin-right: 10px;
            color: #8da2b5;
            vertical-align: middle;
            &.announcement {
              color: #ffae1b;
            }
            &.coming_soon {
              color: #59d457;
            }
            &.improvement {
              color: #71c4ff;
            }
            &.new {
              color: #a174f5;
            }
          }
        }
        .notification_header {
          display: flex;
          justify-content: space-between;
          .tag_date {
            .tag {
              background: #ffae1b;
              padding: 4px 6px;
              font-size: 10px;
              color: $white;
              border-radius: 10px;
              margin-right: 5px;
              overflow: hidden;
              text-transform: uppercase;
              font-weight: 500;

              &.coming_soon {
                background: #59d457;
              }
              &.improvement {
                background: #71c4ff;
              }
              &.new {
                background: #a174f5;
              }
            }
            .date {
              color: #8da2b5 !important;
              font-size: 10px;
              font-weight: 500;
            }
          }
          .share_icon {
            img {
              width: 24px;
              opacity: 0.4;
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
        .notification_headline {
          font-size: 18px;
          font-weight: 700;
          padding: 10px 0 15px;
          a {
            text-decoration: none;
            color: #4b63af;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .notification_description {
          font-size: 14px;
          padding: 7px 0;
          line-height: 1.4;
          color: #424242;
        }
        .notification_img {
          overflow: hidden;
          position: relative;
          &:hover {
            img {
              transform: scale(1.1);
              transition: all 0.6s ease;
            }
            .view_full {
              display: block;
              svg {
                fill: $white;
                width: 50px;
              }
            }
          }
          img {
            width: 100%;
            cursor: pointer;
          }
          .view_full {
            position: absolute;
            bottom: 10px;
            right: 10px;
            display: none;
          }
        }
        .notification_outlink {
          font-size: 14px;
          margin: 10px 0;
          a {
            text-decoration: none;
            color: #4b63af;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .notificarion_response {
          margin: 0 -15px -15px -15px !important;
          background: rgba(156, 156, 156, 0.1);
          padding: 10px;
          margin-top: 35px !important;
          border-top: 1px solid rgba(195, 193, 193, 0.45);
          position: relative;
          // padding-top: 22px;
          height: auto;
          min-height: 65px;

          .emojis {
            display: flex;
            justify-content: center;
            margin-top: -30px;
            align-items: center;
            .emoji {
              margin: 0 6px;
              text-align: center;
              // overflow: hidden;
              img {
                cursor: pointer;
                height: 41px;
                width: 41px;
                border-radius: 50%;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.15);
                &:hover {
                  transform: scale(1.2);
                  transition: all 0.5s ease;
                }
              }
            }
          }
          .comment {
            margin-top: 10px;
            position: relative;
            textarea {
              border: 1px solid #eaeaea;
              border-radius: 2px;
              border-radius: 2px;
              font-size: 13px;
              overflow: hidden;
              padding: 9px 7px 7px;
              resize: none;
              height: 32px;
              color: #424242;
              width: 100%;
              &:focus {
                outline: 0;
                border: 1px solid #eaeaea;
                height: 70px;
                transition: all 0.5s ease;
              }
            }
            .send_icon {
              position: absolute;
              right: 10px;
              bottom: 10px;
              opacity: 0.2;
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }
}

//Right sidebar menu
#right_sidebar_overlay,
#mobile_nav_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 4950;
}

#right_sidebar {
  width: 298px;
  position: fixed;
  top: 0;
  right: -298px;
  height: 100vh;
  z-index: 5000;
  background: $theme_color_light;
  color: $white;
  transition: all 0.3s;
  overflow-y: auto;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  border-left: 2px solid $site_bg;
  &.active {
    right: 0;
  }
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #1e232f;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#right_dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  /*    background: #1e232f;*/
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#right_dismiss:hover {
  background: #fff;
  color: #7386d5;
}

.logged_in_sidebar {
  .side_bar_menu_text {
    padding: 15px;
  }
  .account_menu {
    padding: 0;
    .player_balance_data {
      color: $white !important;
    }
    a {
      text-decoration: none;
    }
    & > li {
      list-style: none;
      padding: 0 10px;
      margin-bottom: 10px;
      .header_deposit_button {
        display: block;
      }
      .has_menu_action {
        color: $white !important;
        .icon {
          color: $white !important;
          float: right;
        }
      }
      a {
        position: relative;
        text-decoration: none;
        color: $white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to right, $site_bg, $black_bg 50%, #fff 50%);
        background-size: 200% 100%;
        background-position: -100%;
        transition: all 0.3s ease-in-out;
        &:before {
          display: block;
          content: "";
          width: 0;
          height: 3px;
          bottom: 5px;
          left: 0;
          bottom: -3px;
          z-index: 0;
          position: absolute;
          background: $site_bg;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          background-position: 0%;
          &:before {
            width: 100%;
          }
        }
        &.active {
          background-position: 0%;
          &:before {
            width: 100%;
          }
        }
      }
    }

    .hasDropdownMenu {
      & > a {
        color: $white;
      }
      ul {
        padding: 0;
        li {
          list-style: none;
        }
      }
      a {
        padding: 10px;
        border-bottom: 1px solid $common_text_color;
        width: 100%;
        display: block;
      }
    }

    .single_slide_menu_item {
      a {
      }
      .icon {
        margin-top: 4px;
        float: right;
      }
    }
    & > li:last-child {
      @media (max-width: 767px) {
        margin-bottom: 100px;
      }
    }
  }
}

$font: "Cairo", sans-serif;
$modal_bg_color: #191919;
$bg_color: #191919;
$main_color: #cfc36e;
$main_color_deep: #cfc36e;
$input_bg_color: #19192c;
$reg_base_color: #cfc36e;
$reg_base_deep_color: #cfc36e;
$input_normal_border_color: #cfc36e;
$input_normal_focused_border_color: #add8e6;

$placeholder_text_color: rgba(0, 0, 0, 0.54);
$input_error_border_color: #e60000;
$input_error_focused_border_color: #e60000;
$input_accept_border_color: #1daa63;
$input_accept_focused_border_color: #1daa63;

.modal-header {
  background: $modal_bg_color;
  color: $white;
  border-color: $theme_color_deep !important;
}
.login_modal {
  background: $modal_bg_color;
  //   position: relative;
  border-radius: 8px;
  padding: 30px 30px !important;
  //   width: 400px !important;
  margin: 0 auto;
  @media (max-width: 575px) {
    // width: 290px !important;
    padding: 10px !important;
  }
  &.reg_section {
    // width: 600px !important;
    @media (max-width: 575px) {
      //   width: 290px !important;
      padding: 10px !important;
    }
  }
  width: 100%;
  .reg_promo_img {
    width: 100%;
    @media (max-width: 768px) {
      width: 50%;
      margin: 15px auto;
    }
  }
  .modal_close {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #454568;
    color: #a8ace2;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: -20px;
    top: -20px;
    cursor: pointer;

    @media (max-width: 767px) {
      right: 5px;
      top: 5px;
    }
  }
  .modal_headline {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #dddff8;
    &.sub_headline {
      font-size: 17px;
      line-height: 25px;
    }
    .backStep {
      float: right;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .login_mode {
    background: $bg_color;
    border-radius: 8px;
    width: fit-content;
    margin: 0 auto;

    .mode_btn {
      margin: 5px;
      display: inline-block;
      height: 36px;
      text-decoration: none;
      width: 100px;
      // border-radius: 6px;
      line-height: 36px;
      font-family: $font;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      text-transform: capitalize;
      color: $white;

      &:hover {
        background: $theme_color_deep;
        color: $black;
      }
      &.active {
        background: $theme_color_deep;
        color: $black;
      }
      @media (max-width: 767px) {
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .privacy_text {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #bebedb;
    a {
      text-decoration: none;
      color: #bebedb;
    }
  }
  .bordered_text {
    padding: 15px;
    background: #19192c;
    border-radius: 5px;
    font-size: 13px;
  }

  &.forgot_pass {
    width: 100%;
  }

  &.bonus_modal {
    color: #fff;
  }
}

.checking {
  .checkbox_with_text {
    width: 100%;
    .checkbox_container {
      vertical-align: sub;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      text-align: center;
      border: 2px solid $theme_color_light;
      padding: 1px;
      label {
        width: 14px;
        height: 14px;
        display: inline-block;
        cursor: pointer;
      }
      input[type="checkbox"] {
        display: none;
        &:checked + label {
          background-color: $theme_color_light;
        }
      }
    }
    .checkbox_text {
      display: inline-block;
      color: #fff;
      font-family: "Cairo", sans-serif;
      width: calc(100% - 35px);
      vertical-align: top;
      line-height: 20px;
      font-size: 12px;
      a {
        color: $theme_color_light;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .error_text {
    /*display: none;*/
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
    word-break: break-word;
    clear: both;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: $input_error_border_color;
    margin: 4px 12px 8px;
  }

  &.error_input {
    .reg_input {
      outline: none;
      border-color: $input_error_border_color;
      -webkit-box-shadow: 0 0 5px $input_error_border_color;
      box-shadow: 0 0 5px $input_error_border_color;
    }
    .input_info {
      display: none;
    }
    .error_text {
      display: block;
    }
  }
}
.form_area {
  margin: 20px 0 10px;
  @media (max-width: 768px) {
    margin: 0px 0 10px;
  }
  .input_item {
    position: relative;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      margin-bottom: 12px;
    }
    .name {
      font-family: $font;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      color: #bebedb;
      margin-bottom: 8px;
      text-transform: capitalize;
      @media (max-width: 768px) {
        margin-bottom: 0px;
      }
      .requried_icon {
        color: red;
      }
      .forgot_pass_link {
        float: right;
        text-decoration: underline;
        cursor: pointer;
        color: $white;
      }
    }
    .three_fields {
      display: flex;
      gap: 20px;
    }
    .name_value,
    .input_box.select_box {
      -webkit-appearance: auto;
      appearance: auto;
      margin-bottom: 0px;
      border-radius: 0.25rem;
      background: #212529;
      color: $white;
      height: 40px;
      font-family: $font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
      border: 2px solid #2f4553;
      // line-height: 48px;
      // border: none;
      &:focus,
      &:hover {
        border: 2px solid #557086;
        outline: none !important;
        box-shadow: none !important;
      }

      @media (max-width: 767px) {
        height: 38px;
        line-height: 38px;
      }
      &.error {
        border-color: red;
      }
      &.accepted {
        border-color: $green;
      }
      &[type="date"] {
        color-scheme: dark;
      }
    }
    .show_hide_pass {
      position: absolute;
      right: 10px;
      top: 42px;
      color: $white;
      @media (max-width: 767px) {
        top: 34px;
      }
    }
    .errorMsg {
      color: red;
      font-size: 12px;
    }

    .dob_input_area {
      display: grid;
      grid-template-columns: 5fr 5fr 6fr;
      grid-gap: 10px;
    }
  }
  .social_buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    .social_btn {
      background: #2f4553;
      color: #d5dceb;
      width: 85px;
      height: 40px;
      border: none;
      border-radius: 5px;
      font-size: 1.125rem;
      line-height: 35px;
      &:hover {
        background: #3d5564;
        transition: all 1s;
      }
    }
  }
  .login_button {
    .login_btn {
      width: 100%;
      height: 44px;
      background: $theme_color_deep;
      color: $black;
      font-family: $font;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      text-transform: capitalize;
      border: none;
      // box-shadow: 0px 3px 0px #5e2d94;
      border-radius: 0;

      &:hover {
        text-decoration: none;
        color: $black;
        background: $theme_color_light;
        // box-shadow: 0px 3px 0px $main_color;
      }

      &:active {
        box-shadow: 0px 3px 0px $theme_color_light !important;
        color: $black;
      }

      @media (max-width: 767px) {
        height: 34px;
        line-height: 17px;
      }

      &.grayBtn {
        background: #2f4553;
      }
    }
  }
}

.errorMsg {
  font-size: 12px;
  color: red;
}

.supportLink {
  img {
    width: 32px;
  }
  .mainIcon {
  }
  .hoverIcon {
    display: none;
    // margin-top: 3px;
  }
  &:hover {
    .mainIcon {
      display: none;
    }
    .hoverIcon {
      display: block;
    }
  }
}
.open_chat,
.language_dropdown {
  .btn-primary {
    background: none !important;
    border: none;
    &::after {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      background: none !important;
      box-shadow: none !important;
    }

    &.dropdown-toggle {
      &:hover,
      &:focus,
      &:active {
        background: none !important;
        box-shadow: none !important;
      }
    }
  }

  .dropdown-item {
    color: #000 !important;
    background: none !important;
    border: none;
    &:hover,
    &:focus,
    &:active {
      background-color: $theme_color_deep !important;
      box-shadow: none !important;
      color: #000 !important;
    }
  }

  img {
    width: 25px;
  }
  .mainIcon {
  }
  .hoverIcon {
    display: none;
    margin-top: 3px;
  }
  &:hover {
    .mainIcon {
      display: none;
    }
    .hoverIcon {
      display: block;
    }
  }
}

.searchbox {
  margin-left: 15px;
  width: 70%;
  position: relative;
  @media (max-width: 500px) {
    display: none !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    width: 40%;
  }
  .search_container {
    border-radius: 10px;
    background-color: $white;
    position: absolute;

    top: 41px;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    // top: 51px;
    -webkit-box-shadow: 0 5px 10px #000;
    box-shadow: 0 5px 10px #000;
    padding-bottom: 10px;
    overflow-y: auto;
    height: 350px;
    padding: 15px !important;
    background-color: #363637;
    width: 300px;
  }
  .search_suggestions {
    background-color: #363637;

    li {
      cursor: pointer;
      color: $white;
      &:hover {
        background: none !important;
        color: $hoveColor;
      }
    }
  }
  input {
    background: #363637;
    border-radius: 25px;
    height: 45px;
    border: none;
    color: #657b83;
    padding-left: 15px;
    font-size: 15px;
    font-weight: 600;
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
      &::placeholder {
        color: #657b83;
        opacity: 1; /* Firefox */
      }
      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #657b83;
      }
    }
    &::placeholder {
      color: white;
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: white;
    }
  }
  .search-icon {
    color: #cccccc;
    font-size: 20px;
    font-weight: 400;
    right: 15px;
    top: 8px;
    .fas {
      font-weight: 300;
      font-size: 15px;
    }
  }
}

.quick_cateogry_link_area {
  // padding-top: 10px;
  .quick_category_link_heading {
    color: $white;
    margin: 10px 0;
    border-bottom: 1px solid #fff;
    font-weight: bold;
  }
  ul {
    li {
      a {
        color: $white;
        text-decoration: none;
        &:hover {
          color: $hoveColor;
        }
      }
    }
  }
}

.openMenuBar {
  padding-left: 8px;
  a {
    .fas {
      color: #fff;
      vertical-align: text-top;
      margin-left: 10px;
      margin-top: 3px;
    }
    &:hover {
      .fas {
        color: $theme_color_light;
      }
    }
  }
}
.menuSearch {
  display: flex;
  align-items: center;
}

.notify_area {
  position: relative;
  .notify_content {
    position: absolute;
    right: 20px;
    top: 10px;
    .nuber_alart_count {
      position: absolute;
      right: -10px;
      top: -2px;
      width: 15px;
      height: 15px;
      line-height: 15px;
      background-color: red;
      color: #000;
      font-size: 12px;
      text-align: center;
      border-radius: 50%;
    }
  }
}
.main_notification {
  position: relative;
  .notification_round {
    width: 5px;
    height: 5px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 12px;
  }
}
