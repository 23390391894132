.home.chat_open {
  transition: all 0.5s ease-in-out;
  position: relative;
  // width: 100vw;

  @media (max-width: 767px) {
    overflow-x: auto;
    height: calc(100vh - 52px);
    height: calc(var(--vh, 1vh) * 100 - 52px);
    @media (orientation: landscape) {
      height: calc(100vh);
      height: calc(var(--vh, 1vh) * 100);
    }
  }
}

.chat_window {
  box-sizing: border-box;
  width: 300px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: $chat_main_bg;
  z-index: 5000;
  padding: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  border-left: 2px solid $theme_color_deep;
  transition: all 0.5s ease-in-out;
  &.full_layout {
    width: 100%;
    position: relative;
    right: unset;
    top: unset;
  }
  &.hidden {
    right: -300px;
  }
  .heading {
    text-align: left;
    border-bottom: 2px solid $chat_msg_bg_1;
    padding: 6px 0 1px 0;
    margin: 0;
    color: $chat_text_color;
    .rooms_dropdown {
      display: flex;
      padding: 0 5px;
      background: none !important;
      border: none;
      &:after {
        display: none !important;
      }
      &:hover,
      &:focus,
      &:active {
        background: none !important;
        box-shadow: none;
      }
    }
    .caret {
      padding-left: 5px;
      line-height: 30px;
      .uparrow {
        display: none;
      }
      .downarrow {
        display: inline;
      }
    }
    .dropdown.show {
      .caret {
        .uparrow {
          display: inline;
        }
        .downarrow {
          display: none;
        }
      }
    }
    .rooms_menu {
      white-space: nowrap;
      padding: 15px !important;
      margin-top: 12px;
      width: 220px;
      button {
        background: none;
        padding: 5px !important;
        margin: 0;
        &:hover {
          background: #dddddd;
        }
      }
      .modal_top_arrow {
        background: $white;
        width: 15px;
        height: 15px;
        top: -7px;
        position: absolute;
        transform: rotate(45deg);
      }
    }
  }
  .close_chat,
  .popup_chat {
    color: $chat_text_color;
    position: absolute;
    right: 20px;
    top: 9px;
    width: 27px;
    height: 27px;
    border-radius: 4px;
    line-height: 25px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
  }
  .popup_chat {
    right: 45px;
    font-size: 1rem;
    top: 7px;
  }
  .chat_box {
    height: 100vh;
    position: relative;
    .messages {
      height: calc(100vh - 100px);
      overflow-y: auto;
      .single_message {
        position: relative;
        background: $chat_msg_bg_1;
        margin: 5px 4px 5px 0;
        border-radius: 5px;
        padding: 3px 7px;
        .user_name {
          color: darken($chat_text_color, 10);
          font-size: 14px;
          display: inline;
        }
        .arrow {
          display: none;
          position: absolute;
          left: -24px;
          border-width: 4px;
          border-left-width: 12px;
          border-right-width: 12px;
          border-style: solid;
          width: 0;
          height: 0;
          box-sizing: content-box;
          border-color: white;
          border-right-color: $chat_msg_bg_1;
        }
        .message_row {
          // display: flex;
          .chat_user {
            display: inline;
          }
        }
        .avatar {
          width: 40px;
          padding: 0 10px;
          display: none;
          img {
            width: 100%;
          }
        }
        .chat_message {
          color: $chat_text_color;
          font-size: 14px;
          display: inline;
        }
        .bottom_row {
          display: none;
          // display: flex;
          justify-content: flex-end;
          color: $chat_text_color;
          font-size: 13px;
          border-top: 1px solid #cccccc;
          margin-top: 3px;
        }
        &.own {
          background: $chat_msg_bg_2;
          .message_row {
            // flex-direction: row-reverse;
            // justify-content: flex-end;
          }
          .bottom_row {
            justify-content: flex-start;
          }
          .arrow {
            border-right-color: white;
            border-left-color: $chat_msg_bg_2;
            right: -24px;
            left: unset;
          }
        }
      }
    }

    .new_message {
      position: absolute;
      width: auto;
      bottom: 60px;
      margin: 15px 0;
      display: flex;
      left: 0;
      right: 0;
      .new_message_input {
        flex: 1;
        border: 1px solid linear-gradient(160deg, $chat_msg_bg_2 0%, $chat_msg_bg_1 100%);
        border-radius: 5px 0 0 5px;
        border-right-width: 0;
        box-shadow: none;
        outline: none;
        border: none;
        padding-left: 5px;
      }

      .remaining_characters {
        background: #f6f6f6;
        line-height: 35px;
        font-size: 11px;
        padding: 0 5px;
      }

      .new_message_submit {
        border: none;
        background: linear-gradient(160deg, $chat_msg_bg_2 0%, $chat_msg_bg_1 100%);
        display: block;
        color: white;
        text-decoration: none;
        padding: 5px 13px;
        border-radius: 0 5px 5px 0;
      }
    }
  }
}
