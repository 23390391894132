.clearfix {
  clear: both;
}
// .custom_swal {
//   text-align: center;
//   .swal-icon {
//     &::after {
//       border: 1px solid red !important;
//     }
//   }
// }

.flag_dropdown {
  .flag_dropdown_btn {
    padding: 5px !important;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    appearance: auto;
    margin-bottom: 0px;
    border-radius: 0.25rem;
    background: #212529;
    color: rgb(255, 255, 255);
    height: 40px;
    font-family: "Cairo", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 2px solid #2f4553;
    &:hover {
      background: #212529 !important;
      outline: none !important;
      box-shadow: none !important;
      border: 2px solid #2f4553 !important;
    }
    &:focus,
    &:active,
    &:visited {
      background: #212529 !important;
      outline: none !important;
      box-shadow: none !important;
      border: 2px solid #2f4553 !important;
    }

    .selectedPhoneCode {
      font-size: 13px;
      img {
        width: 20px;
        vertical-align: text-top;
      }
    }
    &.accepted {
      border-color: $green;
    }
  }
  .flag_dropdown_menu {
    background: #212529;
    // width: 100%;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #212529;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    a {
      padding: 5px !important;
      font-size: 13px;
      color: rgb(255, 255, 255);
      &:hover {
        background: #000;
      }

      img {
        width: 20px;
        // vertical-align: text-top;
      }
    }
  }
}

.swal2-modal {
  background-color: #000 !important;
  border: 1px solid $theme_color_deep !important;
  .swal2-title {
    color: white !important;
  }
  .swal2-text {
    color: white !important;
    text-align: center !important;
  }
  .swal2-icon {
    background-color: #000 !important;
    &::before,
    &::after {
      background-color: #000 !important;
    }
    &::before {
      top: -8px !important;
    }
    .swal2-icon--success__hide-corners {
      background-color: #000 !important;
    }
  }
  .swal2-actions {
    width: calc(100% - 30px);
    justify-content: flex-end;
    button {
      color: black !important;
      background: $theme_color_deep !important;
      outline: none !important;
      opacity: 1 !important;
      box-shadow: none !important;
      padding: 7px 20px;
      &:hover {
        color: black !important;
        background: $theme_color_light !important;
        outline: none !important;
        opacity: 1 !important;
        box-shadow: none !important;
      }
    }
  }
}

.rightContent {
  padding: 0 30px !important;
}
.uppercase {
  text-transform: uppercase;
}
.margin_auto {
  margin: 0 auto;
}
.body {
  font-family: "Myriad Pro";
  background: $site_bg;
  // background-image: url("../assets/images/common/bg.jpg");
  background-repeat: repeat;
  background-attachment: fixed;

  // overflow-x: hidden;
}
.white {
  color: $white;
}
.theme_color {
  color: $theme_color_deep;
}
.dark_white {
  color: $dark_white;
}
.dark_blue {
  color: $dark_blue;
}
.weight_bold {
  font-weight: 600;
}
.paragraph {
  color: $common_text_color;
  font-size: 14px;
  margin-bottom: 1.618em;
  line-height: 1.6;
  &.dark_blue {
    color: rgb(10, 16, 43);
  }
  &.headline {
    font-size: 16px;
    font-weight: 600;
  }
  &.green {
    color: $green_highlight;
  }
  &.italic {
    font-size: 18px;
    font-style: italic;
  }
  &.underline {
    text-decoration: underline;
  }
  &.theme_color {
    color: $theme_color_deep;
  }

  &.no_margin {
    margin-bottom: 0;
  }
  &.dark_white {
    color: $dark_white;
  }
  &.white {
    color: $white;
  }
  a {
    text-decoration: none;
    color: $common_text_color;
    &:hover {
      color: $common_text_color;
    }
  }
  ul {
    li {
      text-align: left !important;
    }
  }
}
.theme_bg {
  background-color: $theme_color_light !important;
}
.radius_25 {
  border-radius: 25px !important;
}

.common_hr {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-bottom-color: #838b97;
  margin: 2.3125em 0px;
}

.width_100 {
  width: 100%;
}

.input_label {
  font-size: 12px;
  align-items: flex-end;
  line-height: 1;
  color: $white;
  margin-bottom: 10px;
  font-weight: 600;
}
.input_box {
  background: $input_bg;
  color: #828282;
  max-height: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 40px;
  border: none;
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  &.bg_grey {
    background: #202020 !important;
  }
  @media (min-width: 1920px) {
    margin-bottom: 40px;
  }

  &.radius_right_o {
    border-radius: 20px 0 0 20px;
    @media (max-width: 575px) {
      border-radius: 20px !important;
    }
  }
  &.active {
    background: $theme_color_light;
  }
  &:focus {
    background: $input_bg;
    color: $white;
    outline: none;
    border: none !important;
    box-shadow: none !important;
  }
  &.select_box {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../assets/images/icons/dropdown-long.png");
    background-size: 35px 35px;
    background-position-x: calc(100% - 3px);
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: #202020;
    padding: 0 10px;
    &.input_bg {
      background-color: $input_bg !important;
    }
  }
}

textarea.input_box {
  max-height: unset !important;
  height: auto !important;
}

.common_btn {
  height: 45px;
  white-space: nowrap;
  text-decoration: none !important;
  font-size: 15px !important;
  font-weight: bold;
  display: block;
  transition: all 500ms;
  padding: 0 30px !important;
  color: $black !important;
  // color: $white !important;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  background-color: $theme_color_light;
  line-height: 45px !important;
  // border-radius: 0.3125em !important;
  // border-radius: 25px;
  border: none;
  outline: 0;
  cursor: pointer;
  .star {
    color: $yellow;
    font-size: 12px;
  }
  &.radius_5 {
    border-radius: 5px !important;
  }
  @media (max-width: 575px) {
    // height: 46px !important;
    // line-height: 46px !important;
  }

  &.no_margin {
    margin: 0 0 !important;
  }

  &:focus {
    border: none;
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    // color: $black !important;
    border-color: transparent;
    background-color: $hoveColor;
  }
  &.with_avatar {
    padding: 0 65px 0 15px !important;
    text-align: left !important;
    @media (max-width: 991px) {
      width: 110px !important;
      padding: 0 10px !important;
      font-size: 13px !important;
      height: 46px !important;
      line-height: 46px !important;
    }
  }
  &.borderd {
    background: none !important;
    border: 1px solid #474f52;
    color: $white !important;
    &:hover,
    &:focus,
    &:active {
      background: $orenge !important;
      border-color: $orenge;
      color: $black !important;
    }
  }
  // size
  &.medium {
    height: 35px !important;
    padding: 0 15px !important;
    line-height: 35px !important;
    font-size: 14px !important;
    &.only_border {
      &::before {
        height: 45px;
        top: -0.5em;
      }
    }
  }
  &.small {
    height: 38px !important;
    min-width: 85px !important;
    padding: 0 !important;
    line-height: 38px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  &.error {
    background: $error;
  }

  // shdow

  &.box_shadow {
    box-shadow: 5px 0 21px #060b16;
  }
  // color
  &.green_bg {
    background: #2bc155 !important;
    color: black !important;
    font-weight: 600 !important;
  }
  &.pink {
    background: #8522b0 !important;
  }
  &.dark_blue {
    background: #192c53;
    border: none;
  }
  &.yellow {
    background-color: $yellow;
    border: none;
  }
  &.inactive {
    background: $orenge;
    border: none;
    color: $black !important;
    &:hover {
      background-color: $theme_color_light;
      color: $black !important;
    }
    &.active {
      background-color: $theme_color_light;
    }
  }
  &.gray {
    background-color: $orenge !important;
    border: none;
  }

  &.only_border {
    position: relative;
    border: 0.125em solid $theme_color_light;
    color: $white !important;
    background-color: transparent;
    &::before {
      background: url("../assets/images/common/gold-button.svg") 50% no-repeat;
      background-size: 100% 100%;
      content: "";
      height: 58px;
      left: -0.125em;
      position: absolute;
      top: -0.6em;
      width: calc(100% + 0.25em);
      line-height: 42px !important;
    }

    &:hover {
      background-color: $hoveColor !important;
      color: $black !important;
    }
  }

  &.reversed_common_btn {
    font-size: 26px !important;
    background-color: $hoveColor;
    &:hover {
      background-color: $theme_color_light;
    }
  }
}

.sidebar_container {
  position: fixed;
  top: 0px;
  z-index: 201;
  // overflow-y: auto;
  .sidebar_area {
    overflow-y: auto;
    width: 250px;
    height: 100vh;
    top: 0px;
    z-index: 1000;
    transition: transform 500ms;
    background-color: $header_bg;

    .sidebar_close_btn {
      color: $white;
      position: absolute;
      font-size: 24px;
      top: 5px;
    }
    .button_close {
      position: absolute;
      right: 14px;
      top: 6px;
      cursor: pointer;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      line-height: 35px;
      text-align: center;
      color: white;
      background: #232323;

      @media (min-width: 1400px) {
        display: none;
      }

      &:hover {
        // background: rgba(77, 166, 234, 0.15);
        background: #eb590e;
        color: #000;
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      svg {
        fill: $theme_color_deep;
      }

      .closeIcon {
        color: #2bc155;
      }
    }
    &.notification_bar {
      width: 400px;
      background: $white;
    }
    &.menu_left_bar {
      transition: all 500ms;
      width: 300px;
      // background: $black_bg;
      background: linear-gradient(90deg, #0f1319, #06090e);
      @media (max-width: 767px) {
        width: 100vw;
      }
      &.reducedMenu {
        width: 70px;
        @media (max-width: 1200px) {
          width: 0;
        }
      }
    }

    &.reducedMenu {
      .logo {
        display: none;
        img {
          width: 70px;
          margin-left: 0px;
          padding-left: 0px;
        }
      }
      .left_sidebar_menus {
        ul {
          li {
            a {
              margin: 0 auto;
            }
          }
        }
      }
      .button_close {
        right: 13px;
      }

      .minimizeSidebar {
        display: none;
        transition: all 2s linear;
      }
    }

    &.extendedMenu {
      .maximizeSidebar {
        display: none;
        transition: all 2s linear;
      }
    }

    .maximizeSidebar,
    .minimizeSidebar {
      // width: 28px;
      height: 15px;
      margin-top: -5px;
    }
  }

  &.right_sidebar {
    right: -260px;
    transform: translateX(0px);
    .show_bar {
      right: 0px;
      transform: translateX(-260px);
    }

    .sidebar_close_btn {
      right: 15px;
    }
    .sidebar_area {
      border-left: 3px solid #6ce934;
    }
  }
  &.notification_sidebar {
    right: -410px;
    transform: translateX(0px);
    .show_bar {
      right: 0px;
      transform: translateX(-410px);
    }
  }

  &.left_sidebar {
    left: -260px;
    transform: translateX(0px);
    .show_bar {
      left: 0px;
      transform: translateX(250px);
    }
  }
  &.menu_left_bar {
    left: -310px;
    transform: translateX(0px);
    @media (max-width: 767px) {
      left: -100vw;
    }
    .show_bar {
      left: 0px;
      transform: translateX(310px);
      @media (max-width: 767px) {
        transform: translateX(100vw);
      }
    }

    &.leftAccountSidebar {
      .show_bar {
        left: 0px;
        transform: translateX(610px);
      }
    }
  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;

  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.category_menu {
  border: 1px solid $theme_color_deep;
  border-left: none;
  border-right: none;
}
.category_menu_and_search_area {
  @media (min-width: 768px) {
    // display: grid;
    // grid-template-columns: 8fr 2fr;
    // grid-gap: 30px;
  }
  .category_menu {
    border: none;
  }
  .searchbox {
    width: 100%;
    position: relative;
    padding-right: 10px;
    @media (max-width: 767px) {
      margin-top: 10px;
      padding-right: 0px;
    }
    @media (max-width: 500px) {
      // display: none !important;
    }
    @media (min-width: 501px) and (max-width: 767px) {
      width: 100%;
    }

    input {
      background: #363637;
      border-radius: 0.3125em !important;
      margin-top: 15px;
      height: 40px;
      border: none;
      color: #657b83;
      padding-left: 40px;
      font-size: 15px;
      font-weight: 600;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
    .search-icon {
      color: #474747;
      font-size: 20px;
      font-weight: 400;
      left: 15px;
      top: 22px;
      @media (max-width: 767px) {
        top: 9px;
      }
    }

    .caretUp {
      width: 20px;
      position: absolute;
      left: 10px;
      top: 42px;
      z-index: 202;
    }
  }
}

.search_suggestions {
  // border-radius: 5px;
  // position: absolute;
  // background: white;
  // top: 41px;
  // width: 100%;
  // -webkit-box-orient: vertical;
  // -webkit-box-direction: normal;
  // -ms-flex-direction: column;
  // flex-direction: column;
  // top: 51px;
  // -webkit-box-shadow: 0 5px 10px #000;
  // box-shadow: 0 5px 10px #000;
  // padding-bottom: 10px;
  // padding-left: 15px;
  // padding-top: 15px;
  // overflow-y: auto;
  // z-index: 201;
  // height: 250px;
  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    list-style: none;
    cursor: pointer;
    margin-bottom: 5px;
    img {
      width: 60px;
      margin-right: 10px;
      border-radius: 5px;
    }

    &:hover {
      background: #dddddd;
    }
  }
  &::before {
    // content: url("../assets/images/common/angle-up-solid.svg");
    // width: 10px;
    // height: 10px;
    // position: absolute;
    // top: -10px;
    // left: 10px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

iframe {
  z-index: -1 !important;
}

.content_page_area {
  strong {
    color: $theme_color_deep;
  }
  p {
    margin-bottom: 0px;
  }
}

.page_title {
  font-size: 24px;
  color: $white;
  margin-bottom: 20px;
}

.blackBg {
  background-color: $black;
}

.slick-slider {
  .slick-arrow {
    &::before {
      color: $theme_color_deep;
    }
  }
}

.view_more {
  background: $theme_color_deep;
  border-radius: 8px;
  position: relative;
  min-height: 90px;
  a {
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;
    padding: 20px;

    .view_more_text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 45px;
      text-transform: capitalize;
      color: $white;
      position: absolute;
      bottom: 5px;
    }
  }
  &:hover {
    transition: margin 0.5s;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

input[type="text"],
input[type="password"],
input[type="email"] {
  font-size: 16px;
}

.reset_password {
  padding: 30px 0;
  .common_headline {
    color: $white;
    font-size: 30px;
  }
  .input_item {
    margin-bottom: 10px;
    .name {
      color: $white;
    }
    .name_value {
      background: none;
      color: $white;
    }
  }
}

.errorMsg,
.error_msg {
  color: red;
  font-size: 12px;
}

main {
  min-height: 300px;

  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) and (max-width: 1700px) {
    min-height: 550px;
    display: grid;
    grid-template-columns: 230px calc(100% - 230px);
  }
}

.red_color {
  // color: red !important;
}

ul {
  li {
    list-style: none;
  }
}

.tooltipStyle {
  position: "absolute";
  background-color: "rgba(255, 100, 100, 0.85)";
  padding: "2px 10px";
  color: "white";
}

.grecaptcha-badge {
  display: none;
}

.gamePlayMsgModal {
  .modal-content {
    border: 1px solid $theme_color_deep;
    text-align: center;
  }
  .modal-header {
    text-align: center;
    border-bottom: none;
    .modal-title {
      text-align: center;
      margin: 0 auto;
    }
  }
  .login_modal {
    border-radius: 0px 0px 8px 8px;
    .fa-exclamation-triangle {
      font-size: 100px;
      margin-bottom: 20px;
    }
  }
  .game_message_content {
    color: $white;
    text-align: center;
    font-weight: bold;
  }
  .modal-footer {
    border-top: none;
    background-color: #191919;
  }
}

.language_dropdown_btn {
  background-color: unset;
  height: 20px;
  line-height: 20px !important;
  text-transform: lowercase;
  padding: 0 3px !important;
  display: flex;
  flex-direction: row;

  .lang_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    color: #fff;

    .world_icon,
    .world_hover_icon {
      width: 20px;
      height: 20px;
    }
    .world_icon {
      display: block;
    }

    .world_hover_icon {
      display: none;
    }

    .lang_text {
      display: block;
      text-transform: uppercase;
      margin-left: 3px;
      font-size: 14px;
    }
  }

  &::after {
    // float: left;
    margin-top: 8px;
    color: white;
  }
  &:hover {
    color: black !important;
    background-color: unset;
    // border-color: $hoveColor;
    .lang_content {
      .world_hover_icon {
        display: block;
      }
      .world_icon {
        display: none;
      }
      .lang_text {
        color: $theme_color_deep;
      }
    }
    &::after {
      color: $theme_color_light;
    }
  }
}

.line_border {
  margin: 15px 0;
  width: 150px;
  ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 10fr 2fr 1fr;
    grid-gap: 5px;
    li {
      display: block;
      height: 3px;
      background-color: $theme_color_deep;
    }
  }
  &.center_align {
    margin: 15px auto;
  }
}

.common_headline_with_bg {
  font-size: 26px;
  font-family: $myriadPro;
  font-weight: bold;

  -webkit-box-shadow: inset 0px 0px 10px 5px #000000;
  box-shadow: inset 0px 0px 10px 5px #000000;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#04090e+0,0b1f2c+17,0e2636+26,0e2737+31,0e2535+38,061119+76,03080d+100 */
  // background: rgb(4, 9, 14); /* Old browsers */
  // background: -moz-linear-gradient(
  //   top,
  //   rgba(4, 9, 14, 1) 0%,
  //   rgba(11, 31, 44, 1) 17%,
  //   rgba(14, 38, 54, 1) 26%,
  //   rgba(14, 39, 55, 1) 31%,
  //   rgba(14, 37, 53, 1) 38%,
  //   rgba(6, 17, 25, 1) 76%,
  //   rgba(3, 8, 13, 1) 100%
  // ); /* FF3.6-15 */
  // background: -webkit-linear-gradient(
  //   top,
  //   rgba(4, 9, 14, 1) 0%,
  //   rgba(11, 31, 44, 1) 17%,
  //   rgba(14, 38, 54, 1) 26%,
  //   rgba(14, 39, 55, 1) 31%,
  //   rgba(14, 37, 53, 1) 38%,
  //   rgba(6, 17, 25, 1) 76%,
  //   rgba(3, 8, 13, 1) 100%
  // ); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(
  //   to bottom,
  //   rgba(4, 9, 14, 1) 0%,
  //   rgba(11, 31, 44, 1) 17%,
  //   rgba(14, 38, 54, 1) 26%,
  //   rgba(14, 39, 55, 1) 31%,
  //   rgba(14, 37, 53, 1) 38%,
  //   rgba(6, 17, 25, 1) 76%,
  //   rgba(3, 8, 13, 1) 100%
  // ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#04090e', endColorstr='#03080d',GradientType=0 ); /* IE6-9 */

  display: inline-block;
  color: $white;
  line-height: 50px;
  // padding: 0 50px 0 15px;
  @media (max-width: 767px) {
    font-size: 18px;
    // padding: 0 15px;
    line-height: 30px;
  }
}

.night_banner_area {
  border-radius: 10px;
  padding: 0 15px !important;
  .single_night_banner {
    text-decoration: none;
    border-radius: 10px;
    background-color: #04070c;
    .night_banner_text {
      padding: 15px;

      @media (max-width: 767px) {
        padding: 15px 0 !important;
      }

      .heading_text {
        font-size: 40px;
        text-decoration: none;
        color: $white;
        @media (max-width: 991px) {
          font-size: 30px;
          line-height: 30px;
        }
      }
      .info_text {
        color: $white;
        margin: 30px 0;
        font-size: 14px;
        text-decoration: none;
        @media (max-width: 991px) {
          margin: 10px 0;
        }
      }
    }
    .night_banner {
      width: 100%;
    }

    &:hover {
      .night_banner {
        -webkit-filter: brightness(70%);
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
      }
    }
  }
}

.lime_green_text {
  color: $theme_color_deep;
}

body,
.body {
  min-height: 100vh;
}

.gameLoadingProgress {
  margin: 15px 0;
  .gameLoadingInfo {
    font-size: 12px;
    color: $white;
    text-align: center;
  }

  .progress {
    height: 5px;
    .progress-bar {
      background-color: $theme_color_deep !important;
    }
  }
  &.wagerRequirementBar {
    .progress {
      height: 12px;
    }
  }
}

.ticket_details_container {
  .common_title {
    color: $white;
  }
  .tickets_list {
    color: $white;
  }
  .conversation_reply_area {
    padding-top: 10px;
  }
}

.single_block {
  .item {
    position: relative;
  }
  .in_input_icon {
    position: absolute;
    top: 35px;
    left: calc(100% - 30px);
  }
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

.jactpor_ticker_area {
  h2 {
    color: $white;
    text-align: center;
  }
  section {
    & > span {
      margin: 0 2px;
      border-radius: 5px;
      // padding: 5px;
      color: $white;
      font-size: 15px !important;
      // padding-top: 0;
      // display: flex !important;
      // justify-content: center !important;
      // align-items: center !important;
      &[style] {
        box-shadow: 1px 1px 1px #000;
      }
    }
    span {
      font-size: 25px !important;
    }
  }
  &.gameTicker {
    section {
      & > span {
        margin: 0 2px;
        border-radius: 3px;
        // padding: 5px;
        color: $white;
        font-size: 12px !important;
        padding-top: 0;
        // -webkit-padding-before: 3px;
        &[style] {
          box-shadow: 1px 1px 1px #000;
        }
      }
      span {
        font-size: 12px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .mobile_search_area {
    .searchbox {
      display: block !important;
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

@media (min-width: 768px) {
  .mobile_search_area {
    display: none;
  }
}

.clock_area {
  color: #fff;
  text-align: center;
  // @media (min-width: 1199px) and (min-height: 300px)  {
  //   position: absolute;
  //   bottom: 10px;
  // }
  width: 100%;
  font-size: 18px;
}

.support_btn {
  img {
    width: 25px;
  }
  .normalImg {
    display: block;
  }
  .hoveredImg {
    display: none;
  }
  &:hover,
  &:active {
    .normalImg {
      display: none;
    }
    .hoveredImg {
      display: block;
    }
  }
}

.modal-content {
  .modal-header {
    .close {
      color: #fff !important;

      &:hover,
      &:focus,
      &:active {
        color: $yellow !important;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.no_records_found {
  color: #fff;
  text-align: center;
  // position: absolute;
  // top: 50%;
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
}

.read_more_btn {
  color: #fff;
  &:hover,
  &:focus,
  &:active {
    color: $theme_color_deep;
  }
}

.no_padding {
  padding: 0px !important;
}

p {
  margin: 0 !important;
}

.not_found_container {
  height: 100vh;
  position: relative;
  text-align: center;
  .not_found_area {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    color: #fff;
    a {
      color: $theme_color_deep;
      text-decoration: none;
      &:hover {
        color: $orenge;
        text-decoration: underline;
      }
    }
  }
}

.swal-no-border-icon {
  border: none !important;
  & > div {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.common_text {
  color: $white;
}

.dropdown_main_menu {
  max-height: 90vh;
  overflow-y: auto;
}

.error {
  color: red;
  font-size: 12px;
}
