.wheel_modal {
  width: 635px !important;
  max-width: unset !important;
  .lucky_wheel {
    border-radius: 20px;
    .wheel_container {
      img {
        max-width: unset !important;
      }
      .wheel_canvas_outer {
        position: relative;
        margin: 0 auto;
        width: 600px;
        @media (max-width: 767px) {
          width: 250px !important;
        }
        .wheel_frame {
          position: relative;
          width: 600px;
          z-index: 50;
          @media (max-width: 767px) {
            width: 250px !important;
          }
        }
        .wheel_canvas {
          position: absolute;
          top: 48px;
          left: 48px;
          z-index: 1;
          @media (max-width: 767px) {
            width: 214px !important;
            top: 24px !important;
            left: 18px !important;
            height: auto;
          }
        }
      }
      .spin_button {
        padding: 0 30px;
        margin: 40px auto;
      }
    }

    .wheel_top_content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1199px) {
        display: block;
        text-align: center;
      }
      .item {
        img {
          @media (max-width: 767px) {
            width: 100%;
          }
          @media (min-width: 1200px) and (max-width: 1500px) {
            max-width: 400px;
          }
        }
      }
    }
    .claim_button {
      margin-top: 15px;
      a,
      button {
        border: none;
        display: block;
        margin: 0 auto;
        &.disabled,
        &.disabled:hover {
          background: gray;
          cursor: default;
        }
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
    .wheel_text_area {
      padding: 30px 60px;
      @media (max-width: 991px) {
        padding: 30px;
      }
      .progress {
        border-radius: 50px;
        border: 1px solid #0f2749;
        background: #001a35;
        .progress-bar {
          border-radius: 50px;
          border: 1px solid #0f2749;
          background: linear-gradient(180deg, #09cef5 0%, #1085ff 100%);
        }
      }
    }
  }
}
