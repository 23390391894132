.reward_area {
  padding-top: 45px;
  .progress {
    border-radius: 50px;
    border: 1px solid #0f2749;
    background: #001a35;
    .progress-bar {
      border-radius: 50px;
      border: 1px solid #0f2749;
      background: linear-gradient(180deg, #09cef5 0%, #1085ff 100%);
    }
  }
  .reward_banner {
    position: relative;
    border-radius: 10px;
    img {
      width: 100%;

      &.desktopBanner {
      }
      &.mobileBanner {
      }
    }
    .reward_content {
      position: absolute;
      left: 50px;
      top: 50%;
      width: 40%;
      transform: translateY(-50%);
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        left: 0;
        top: 40%;
      }
      .banner_heading {
        color: #fff;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .banner_conntent {
        color: #9db8e1;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .reward_user_section {
    margin-top: 50px;
    background-color: #0f2749;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 5fr;
    padding: 20px 30px;
    grid-gap: 30px;
    border-radius: 10px;
    @media (max-width: 767px) {
      grid-gap: 15px;
    }
    .left {
      &.rank_icon {
        color: #fff;
        img {
          width: 100px;
        }
      }
    }
    .right {
      .user_name {
        color: #fff;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      .progress_area {
        .progress_top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          .achieve_points {
            color: #fff;
          }
          .required_points {
            color: rgba(255, 255, 255, 0.3);
          }
        }
        .progress_middle {
          .progress {
          }
        }
        .progress_bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          .current_level {
            color: #fff;
            label {
              color: rgba(255, 255, 255, 0.3);
              margin-right: 5px;
            }
          }
          .next_level {
            color: #fff;
            label {
              color: rgba(255, 255, 255, 0.3);
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .wager_countdown_section {
    margin-top: 50px;
    background-color: #0f2749;
    padding: 30px;
    border-radius: 10px;
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 30px 10px;
    }
    .countdown_heading {
      color: #fff;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 29px;
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 18px;
      }
    }
    .countdown_block {
      background-color: #001a35;
      color: #fff;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        padding-right: 10px;

        label {
          margin-bottom: 0px;
          color: rgba(255, 255, 255, 0.3);
          margin-right: 5px;
        }
      }
    }
  }
  .level_menu {
    padding: 50px 0;
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      gap: 30px;
      @media (min-width: 768px) and (max-width: 991px) {
        gap: 20px;
      }
      @media (min-width: 500px) and (max-width: 768px) {
        gap: 10px;
      }

      li {
        a {
          color: rgba(255, 255, 255, 0.3);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
          display: block;
          padding: 10px 15px;
          border-radius: 5px;
          @media (max-width: 991px) {
            font-size: 16px;
          }
          @media (max-width: 767px) {
            font-size: 14px;
          }
          &:hover,
          &.active {
            background-color: #0f2749;
            color: #fff;
          }
        }
      }
    }
  }
  .reward_items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    border-radius: 10px;
    @media (min-width: 768px) and (max-width: 991px) {
      gap: 30px;
    }
    @media (min-width: 500px) and (max-width: 768px) {
      gap: 15px;
    }
    @media (max-width: 500px) {
      display: block;
    }
    .single_reward_item {
      background: #0f2749;
      text-align: center;
      padding: 30px;
      border-radius: 10px;
      @media (max-width: 677px) {
        margin-bottom: 30px;
      }
      img {
        width: 120px;
        margin-top: -50px;
      }
      .reward_item_title {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 15px;
      }
      .reward_item_sub_title {
        color: #9db8e1;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .progress {
        margin-bottom: 5px;
      }
    }
  }

  .rank_lists_area {
    padding-top: 50px;
    // display: flex;
    gap: 30px;
    overflow: hidden;
    .slick-list {
      overflow: visible;
      .slick-slide {
        // padding: 0 15px;
      }
    }
    .item_outer {
      padding: 0 15px;
    }
    .rank_item {
      background: #0f2749;
      text-align: center;
      border-radius: 10px;
      padding: 15px;
      opacity: 0.6;
      &.active,
      &:hover {
        opacity: 1;
      }
      // min-width: 250px;
      // width: 95%;
      // margin: 0 15px;

      img {
        width: 80px;
        margin: 0 auto;
        margin-top: -55px;
      }
      .rank_title {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .rank_details {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        label {
          display: block;
          color: #9db8e1;
          strong {
            color: #fff;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
