.dir_rtl {
  // common.scss
  main {
    @media (min-width: 1400px) and (max-width: 1700px) {
      // grid-template-columns: calc(100% - 230px) 230px;
    }
  }
  .input_box.select_box {
    background-position-x: 0;
  }
  .sidebar_container.menu_left_bar {
    left: unset;
    right: -310px;
    @media (max-width: 767px) {
      right: -100vw;
    }
    .show_bar {
      -webkit-transform: translateX(-310px);
      transform: translateX(-310px);
      @media (max-width: 767px) {
        -webkit-transform: translateX(-100vw);
        transform: translateX(-100vw);
      }
    }
  }

  .language_dropdown_btn {
    text-align: left !important;
  }

  // header.scss
  .login_modal .modal_close {
    left: -20px;
    right: unset;
  }

  .searchbox .search-icon {
    left: 8px;
    right: unset;
  }

  .header_menu {
    .user_area {
      ul {
        li {
          a {
            text-align: right;
            svg,
            img {
              margin-left: 10px;
            }
            .count {
              left: unset;
              right: 8px;
            }
          }
        }
      }
    }
  }

  .main_notification .notification_round {
    right: unset;
    left: 12px;
  }

  .notify_area .notify_content {
    left: 20px;
    right: unset;
  }

  // cashier-modals.scss
  .wallets_menu div,
  .wallets_menu span,
  .wallets_menu input {
    text-align: right;
  }
  .deposit_block .deposit_amount {
    padding-right: unset;
    padding-left: 2px;
  }

  .deposit_block .wallet_caret {
    padding-right: 10px;
    padding-left: unset;
  }

  .wallets_menu .wallets_balance .currency .currency_name {
    text-align: left;
  }

  // footer.scss
  .present_area {
    right: unset;
    left: 100px;
  }

  //games.scss
  .category_games_header {
    .slider_controlls {
      direction: ltr;
    }
  }

  //left_side.scss
  .pre_left_bar .pre_menu_area ul li .text {
    padding-left: unset;
    padding-right: 5px;
  }

  // chat.scss
  .support_panel {
    right: unset;
    left: -435px;
    &.open {
      left: 0;
    }
  }

  // support_sidebar.scss
  .support_panel .close_icon {
    left: 0;
    right: unset;
  }

  // landing.scss
  .access_control_area {
    .chimp-accordion {
      .accordion-header {
        .accordion-button {
          &::after {
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
    }
  }

  // promotions.scss
  .promotion_area .promotions .single-promotion h5 {
    text-align: right;
  }

  // user_dashboard.scss
  .user_dashboard .close_icon {
    left: 5px;
    right: unset;
  }
  .user_dashboard .right_area.table_area .data_row .item .action_icon {
    margin-right: 0;
    margin-left: 15px;
  }

  // notifications.scss
  .message-item,
  .player_message_container .notification-item {
    padding-right: unset;
    padding-right: 20px;
  }
  .player_message_container .message-item .delete_msg {
    right: unset;
    left: 0;
  }
}
