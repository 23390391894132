// $theme_color_deep: #9460e9;
// $theme_color_light: #9b5fec;

$theme_color_deep: #8F882E;
$theme_color_light: #D8CF6B;

$orenge: $theme_color_deep;

$second_color: #232425;

$playBtnBG: $second_color;

$white: rgb(255, 255, 255);
$yellow: #fee600;
$error: #e80a36;
$site_bg: #000;
$black_bg: #04070c;
$black: black;

$dark_blue_bg: #0e1830;
$footer_bg: $dark_blue_bg;
$header_bg1: #10294f;
$header_bg2: #1d3b6a;

$header_bg: linear-gradient(to right, $header_bg1, $header_bg2);

$input_bg: rgb(30, 29, 29);

$common_text_color: #7d8386;
// $green: rgb(52, 196, 117);
// $green_highlight: rgb(41, 156, 93);
$green: #109648;
$green_highlight: #037633;
$background_blue_dark: #050a1e;
$light_blue: rgb(77, 166, 234);
$light_blue_highlight: rgb(33, 113, 206);

$dark_white: #c7cbe4;
$dark_blue: #050a1e;

$hoveColor: $orenge;

$custom-hover-color: #4de8ff;
$social-icon-hover-color: #bf40bf;

@import url("https://fonts.googleapis.com/css?family=Inconsolata:700");

$chat_main_bg: $site_bg;
$chat_msg_bg_1: $header_bg1;
$chat_msg_bg_2: $black_bg;
$chat_text_color: $white;

$openSene: "Open Sans";
$myriadPro: "Myriad Pro";
