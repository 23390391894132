.access_control_area {
  color: $common_text_color;
  .details__heading {
    margin-bottom: 15px;
  }
  .fa-bell {
    color: #000;
  }

  .file_upload {
    cursor: pointer;
    display: inline-block;
    #id_image,
    #poa_image,
    #other_image {
      display: none;
    }
    .upload_id_btn {
      display: inline-block;
      width: auto;
      padding: 0 15px;
    }

    background-color: $theme_color_light;
    border-color: $theme_color_deep;
    border-bottom: 4px solid $theme_color_deep;
    &:hover {
      color: #fff;
    }
  }

  .photo_view_area {
    margin: 10px 0;
    width: 300px;
    position: relative;
    .led-glow {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      &.led-green,
      &.led-approved {
        background-color: #abff00;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
          #89ff00 0 2px 12px;
      }
      &.led-yellow,
      &.led-submitted {
        background-color: #ff0;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
          #ff0 0 2px 12px;
      }
      &.led-red,
      &.led-rejected {
        background-color: #f00;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
          rgba(255, 0, 0, 0.5) 0 2px 12px;
      }
    }
    .id_image_preview {
      width: 300px;
    }
  }

  .player_responsible_area {
  }

  .progress {
    display: none;
    margin-bottom: 10px;
  }

  .chimp-accordion {
    margin-bottom: 20px;
    position: relative;
    border: none !important;
    background: none !important;
    .fa-bell {
      position: absolute;
      left: 130px;
      top: 16px;
      z-index: 1;
      color: #fff;
    }
    // .fa-bell {
    //   right: 4px;
    // }

    .accordion-header {
      .accordion-button {
        font-weight: bold;
        font-size: 16px;
        margin: 0;
        border-radius: 4px;
        position: relative;
        color: $white;
        padding: 0 15px;
        line-height: 50px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from($theme_color_deep),
          to($theme_color_light)
        );
        background: linear-gradient(
          to bottom,
          $theme_color_deep 0%,
          $theme_color_light 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$theme_color_deep', endColorstr='$theme_color_light',GradientType=0 );
        border: none;
        &:focus {
          box-shadow: none;
        }
        // &::after {
        //   display: none;
        // }
        i {
          font-size: 20px;
          float: left;
        }
        &:not(.collapsed) {
          color: #fff;
          border-color: transparent;
          -webkit-box-shadow: none;
          box-shadow: none;
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from($theme_color_light),
            to($theme_color_deep)
          );
          background: linear-gradient(
            to bottom,
            $theme_color_light 0%,
            $theme_color_deep 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$theme_color_light', endColorstr='$theme_color_deep',GradientType=0 );
        }
      }
    }

    .accordion-body {
      padding: 20px;
      background: $site_bg;
      color: white;
      .form-control {
        margin-bottom: 15px;
        background: $background_blue_dark;
        color: white;
        border: none;
        outline: none;
        input {
          outline: none;
        }
      }
    }

    .player_responsible_area {
      .checkbox_container {
        input[type="checkbox"] {
          vertical-align: middle;
        }
      }
    }
  }

  &.payment_methods_accordions {
    .accordion-body {
      padding: 20px 0 0 0;
      background: none;
      .form-control {
        margin-bottom: 15px;
        background: $background_blue_dark;
        color: white;
        border: none;
        outline: none;
        input {
          outline: none;
        }
      }
    }
  }

  &.verify_area {
    .fa-exclamation {
      color: red;
    }
    .fa-check {
      color: $theme_color_deep;
    }
  }
  &.payment_methods_accordions {
    .chimp-accordion {
      .accordion-header {
        .accordion-button {
          height: 90px;
          font-size: 44px;
          &.collapsed{
            background-color: #000 !important;
          }
          &::after {
            width: 25px;
            height: 25px;
            background-size: contain;
          }
        }
      }
    }
  }
}

.myaccount_input_area {
  background-color: #202020 !important;
}
