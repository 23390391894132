@import "./config";
.blacklist_page {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: $second_color;
  color: $white;
  .logo {
    padding-top: 40px;
    text-align: center;
    img {
      max-width: 200px;
    }
  }

  .title {
    font-size: 25px;
    margin: 30px auto;
  }

  .sub_text {
    font-size: 15px;
  }

  .page_content {
    max-width: 800px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .left_content,
    .right_content {
      padding: 20px;
      .content_icon {
        margin-bottom: 15px;
        .fa {
          font-size: 30px;
        }
      }
      .content_title {
        font-size: 20px;
      }
      .content {
        font-size: 13px;
      }
      .contact_mail {
        a {
          color: $light_blue_highlight;
        }
      }
    }
  }
}
