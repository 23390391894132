.player_message_container {
  .messages-list,
  .notifications-list {
    margin: 15px 0 0 0;
    padding: 0;
    list-style: none;
  }
  .message-item,
  .notification-item {
    word-break: break-all;
    color: $common_text_color;
    width: 100%;
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #c2c2c2;
    font-size: 13px;
    line-height: 16px;
    padding-right: 20px;
    position: relative;
    p {
      margin: 0;
    }
    /*    background: url('../assets/images/icons/notification-icon-sm.png') no-repeat top left;*/
    .delete_notification {
      position: absolute;
      right: 0px;
      top: -4px;
      font-size: 22px;
      color: $white;
      &:hover {
        color: $custom-hover-color;
      }
    }

    .button {
      width: 140px;
      display: block;
      margin-top: 10px;
    }
  }
  .message-item {
    /*    background: url('../assets/images/icons/message-icon-sm.png') no-repeat top left;*/
    .delete_msg {
      position: absolute;
      right: 0px;
      top: -4px;
      font-size: 22px;
      color: $white;
      &:hover {
        color: $custom-hover-color;
      }
    }
  }
  .message-item h4,
  .notification-item h4 {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .message-item h4 {
    margin-bottom: 5px;
  }
  .notification-item p {
    margin: 0;
  }
  .del-message,
  .del-notification {
    position: absolute;
    right: 0;
    top: 3px;
    color: #44a309;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 20px;
  }
  .del-message:hover,
  .del-notification:hover {
    color: #33720e;
  }
  .messages-section {
    margin-bottom: 40px;
  }
}

.player_message_modal {
  background: $site_bg;
  border-radius: 8px;
  padding: 30px 30px !important;
  margin: 0 auto;
  color: $white;
  width: 100%;
  .modal_close {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #454568;
    color: #a8ace2;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: -20px;
    top: -20px;
    cursor: pointer;
    @media (max-width: 767px) {
      right: 5px;
      top: 5px;
    }
  }
  .modal_headline {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #dddff8;
  }
}
