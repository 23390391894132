@keyframes game-image-loading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.category_games {
  .slider_heading {
    font-size: 55px;
    color: $white;
    line-height: 1.2;
    margin-bottom: 20px;
    @media (max-width: 575px) {
      text-align: center;
      font-size: 40px;
    }
  }
  .slider_dots {
    display: flex;
    width: max-content;
    margin-left: 30px;
    @media (max-width: 575px) {
      margin: 0 auto;
    }
    gap: 20px;
    align-items: center;
    .indicator {
      color: $white;
      font-size: 16px;
      cursor: pointer;
    }
    .circles {
      display: flex;
      gap: 5px;
      font-size: 12px;
      color: #404b51;
      .active {
        color: $theme_color_light;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .custom_slider {
    // overflow: hidden;
    margin-bottom: 50px;
    position: relative;

    &:focus {
      outline: none;
    }
    .flickity-viewport {
      .flickity-slider {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
      }
    }
  }
}
.category_games_header {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  align-items: center;
  @media (max-width: 767px) {
    margin: 10px 0;
  }
  .headline_left {
    align-items: center;
    display: flex;
    gap: 10px;
    .game_headline {
      color: $white;
      line-height: 1;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 0;
      align-items: center;
      .svg_icon {
        fill: $white;
        width: 35px;
        height: 33px;
        margin-top: -6px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        font-weight: 400;
      }
      .arrow_right {
        margin-left: 25px;
        height: 30px;
        width: 30px;
        border: 1px solid $theme_color_deep;
        border-radius: 50%;
        font-size: 13px;
        text-align: center;
        color: $theme_color_deep;
        line-height: 28px;
        a {
          text-decoration: none;
          color: $theme_color_deep;
        }
      }
      .catalog_text {
        font-size: 13px;
        margin-left: 10px;
        a {
          text-decoration: none;
          color: $white;
        }
      }
    }
  }
  .headline_right {
    display: flex;
    .link {
      text-decoration: none;
      font-size: 16px;
      color: $white;
    }
    .searchbox {
      margin-right: 20px;
    }
  }

  .slider_controlls {
    .prev {
      background: none;
      box-shadow: none;
      margin: 0 5px 0 0;
      height: 30px;
      width: 30px;
      border: none;
      opacity: 0.5;
      color: $white;
      border-radius: 50%;
      font-size: 14px;
      text-align: center;
      &:focus {
        opacity: 1;
        background: none !important;
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
      }
      &:hover {
        opacity: 1;
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
        background: $theme_color_light !important;
      }
      &.next {
        margin: 0 0 0 5px;
      }
    }
  }
}
.category_menu_and_search_area {
  border: 1px solid $theme_color_deep;
  border-left: none;
  border-right: none;
  border-radius: 3px;
  text-align: center;
}

.games_filter {
  background: none;
  padding: 10px 0 7px 0;
  // border-left: 1px solid $theme_color_deep;
  // border-top: 1px solid $theme_color_deep;
  // border-bottom: 1px solid $theme_color_deep;
  @media (max-width: 767px) {
    // display: none;
  }
  &.mobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .menus {
    overflow: auto hidden;
    scroll-behavior: smooth;
    @media (max-width: 767px) {
      padding: 10px 0 5px 0;
    }
    ul {
      margin: 10px 0 5px 0;
      padding: 0;
      @media (min-width: 768px) {
        display: inline-flex;
        align-items: center;
        align-self: center;
      }
      @media (max-width: 767px) {
        display: flex;

        flex-wrap: nowrap;
        overflow-x: auto;
        grid-template-columns: repeat(3, 1fr);
        // grid-gap: 30px;
      }

      li {
        list-style: none;
        text-align: center;
        font-size: 14px;
        flex-wrap: nowrap;
        white-space: nowrap;
        font-weight: 600;
        // margin-right: 65px;
        background: none;
        margin: 0 30px;
        @media (max-width: 767px) {
          margin: 0 10px;
        }
        a {
          // padding: 14px 16px;
          text-decoration: none;
          color: $white;
          // line-height: 50px;
          text-align: center;
          flex-wrap: nowrap;
          position: relative;
          transition: all 0.3s ease-in-out;
          font-weight: 500;
          @media (max-width: 767px) {
            gap: 10px;
          }
          &:hover {
            color: $theme_color_deep;
          }
          &.active {
            // color: $theme_color_deep;
            // border-bottom: 2px solid $theme_color_light;
            color: $theme_color_deep;
            background: none !important;
            .menu_icon {
              .main_icon {
                display: none !important;
              }
              .hover_icon {
                display: block !important;
              }
            }
          }

          @media (max-width: 767px) {
            border-radius: 0.3125em !important;
            margin: 5px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            line-height: 25px;
            position: relative;
            color: $white !important;
            background-color: transparent;
            &::before {
              background: none;
              border: none;
            }
            .svg_icon {
              width: 21px;
              height: 21px;
              fill: white;
            }
            &.big_icon {
              .svg_icon {
                width: 27px;
                height: 27px;
                margin-top: -4px;
              }
            }
            &:hover,
            &.active {
              background-color: none !important;
              border-bottom: none;
              span {
                color: $theme_color_deep;
              }
              .svg_icon {
                fill: $theme_color_deep;
              }
            }
          }
        }

        .menu_icon {
          width: 30px;
          .main_icon {
            width: 30px;
            display: block;
            text-align: center;
            margin: 0 auto;
          }
          .hover_icon {
            width: 30px;
            display: block;
            text-align: center;
            margin: 0 auto;
            display: none;

            &.slotsIcon {
              width: 30.4px;
              margin-top: -1px;
              // margin-left: -1px;
              @media (max-width: 767px) {
                margin-left: 0px;
              }
            }

            &.newGamesIcon {
              width: 27.9px;
              // margin-top: 0px;
              // margin-left: 0px;
            }

            &.liveCasinoIcon {
              width: 29.5px;
            }
          }

          .liveCasinoIcon {
            // margin-top: -4px;
          }
          .newGamesIcon {
            // margin-top: -2px;
            width: 25.5px;
          }
          .slotsIcon {
            // margin-right: -5px;
            margin-left: 8px;
            width: 31px;
            @media (max-width: 767px) {
              margin-left: 0px;
            }
          }
          .otherGamesIcon {
            margin-top: 1px;
            width: 25.5px;
          }
          .liveCasinoIcon {
            width: 25.5px;
          }
          .popularIcon {
            width: 25.5px;
          }

          .jackpotIcon {
          }
        }
        .menu_text {
        }

        &:hover,
        &.active {
          .menu_icon {
            .main_icon {
              display: none !important;
            }
            .hover_icon {
              display: block !important;
            }
          }
          .menu_text {
            color: $theme_color_deep;
          }
        }
      }
    }
  }
}
.game_content_section {
  margin-bottom: 50px;
}
.game_list {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;

  &.live-casino {
    // grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
  }
  // @media (min-width: 1800px) and (max-width: 2199px) {
  //   grid-template-columns: repeat(6, 1fr);
  //   grid-gap: 30px;
  // }
  // @media (min-width: 2200px) {
  //   grid-template-columns: repeat(7, 1fr);
  //   grid-gap: 30px;
  // }
}

.single_gameBAK {
  border-radius: 5px;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  .games_image {
    overflow: hidden;
    // border-radius: 5px;
    img {
      width: 100%;
      max-height: 180px;
      @media (max-width: 575px) {
        max-height: 180px;
      }
      @media (min-width: 320px) and (max-width: 360px) {
        max-height: 105px;
      }
      @media (min-width: 361px) and (max-width: 460px) {
        max-height: 135px;
      }
      @media (min-width: 461px) and (max-width: 575px) {
        max-height: 160px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        max-height: 160px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        max-height: 170px;
      }
      border-radius: 15px;
      overflow: hidden;
      border-radius: 5px;
      transition: transform 0.5s;
    }
  }

  .game_overlay {
    transition: display 2s;
    display: none;
    padding: 15px;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    .provider_name {
      font-size: 16px;
      font-weight: bold;
      @media (max-width: 575px) {
        font-size: 14px;
      }
      &.provider {
        font-size: 13px;
      }
    }
    .game_name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:hover {
    .games_image {
      img {
        transform: scale(1.2);
      }
    }
    .game_overlay {
      display: flex;
    }
  }
}

.single_game {
  background: linear-gradient(90deg, rgb(15, 19, 25), rgb(6, 9, 14));
  // border-radius: 10px;
  padding: 5px;
  transition: all 500ms;
  // padding-bottom: 35px !important;
  position: relative;
  @media (max-width: 575px) {
    padding: 5px;
  }

  // @media (min-width: 1800px) {
  //   max-width: 235px;
  //   margin: 0 auto;
  // }

  .games_badge {
    background: linear-gradient(135deg, rgb(251, 94, 89), rgb(231, 47, 53));
    position: absolute;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 25px;
    cursor: pointer;
    left: 25px;
    font-size: 18px;
    color: red;
    line-height: 1.1em;
    text-transform: uppercase;
    @media (max-width: 575px) {
      top: 20px;
      left: 20px;
    }
  }
  .games_image {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    text-align: center;
    // border-radius: 10px;
    img {
      width: 100%;
      transition: transform 0.5s;
      &.loading {
        animation-iteration-count: infinite;
        animation-name: game-image-loading;
        animation-duration: 1s;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
    .gamePlayBtn {
      border: 1px solid $theme_color_deep;
      padding: 0 30px;
      line-height: 30px;
      display: none;
      border-radius: 3px;
      background-color: $theme_color_deep;
      color: #000;
      font-size: 14px;
      text-decoration: none;
      position: absolute;
      top: 50%;
      transition: all 500ms;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
      z-index: 5;
      &:hover,
      &:active {
        background-color: $orenge;
        border-color: $orenge;
        color: #000;
      }
    }
  }
  .games_badge {
    top: -2px;
    left: 10px;
    z-index: 10;
  }
  .provider_name {
    font-size: 8px;
    text-transform: uppercase;
    font-family: "Helvetica";
  }

  .game_name {
    height: 45px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    text-align: center;
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }

  .games_rating {
    margin-top: 12px;
    display: flex;
    width: 100%;
    padding: 0 15px;
    left: 0;
    justify-content: space-between;
    align-items: center;
    font-size: 12px !important;
    position: absolute;
    bottom: 10px;
    @media (max-width: 575px) {
      padding: 0 10px;
    }
    .star {
      color: $yellow;
      font-weight: bold;
    }
    .view {
      color: #304970;
      font-weight: bold;
    }
  }
  .game_overlay {
    transition: display 2s;
    display: none;
    padding: 15px;
    text-align: center;
    // background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    .provider_name {
      height: 45px;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      bottom: 6px;
      width: 100%;
      left: 0;
      text-align: center;
      @media (max-width: 575px) {
        font-size: 13px;
      }
    }

    .game_play_button {
      margin-top: 23%;
    }
  }

  .game_player_btn {
    display: none;
    text-align: right;
    img {
      width: 30px;
      float: right;
    }
    .greenBtn {
      display: block;
    }
    .blueBtn {
      display: none;
    }
  }
  &:hover {
    .game_overlay {
      display: block;
      background: rgba(0, 0, 0, 0.6);
      z-index: 3;
    }
    .games_image {
      img {
        transform: scale(1.2);
        // opacity: 0.6;
      }
      .gamePlayBtn {
        display: inline-block;
      }
    }
    .game_overlay {
      // display: block;
    }
    // background: linear-gradient(90deg, rgb(55, 148, 13), rgb(61, 167, 13));
    .common_btn {
      background-color: $hoveColor;
    }
    .game_player_btn {
      .greenBtn {
        display: none;
      }
      .blueBtn {
        display: block;
      }
    }
  }
  .game_play_button {
    background-color: $playBtnBG;
    color: $white !important;

    &:hover {
      color: $black !important;
      border-color: transparent;
      background-color: $hoveColor;
    }
  }

  .game_footer {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    .provider_name {
      color: $white;
    }

    @media (max-width: 400px) {
      display: block;
    }
  }
}

.single_slideBAK {
  // max-width: 408px;
  position: relative;
  // max-height: 300px;
  width: 100%;
  background-color: $black_bg;
  border: 2px solid rgba(73, 73, 77, 0.6);
  border-radius: 8px;
  // margin-right: 15px;
  .slide_bg_image {
    width: 100%;
  }
  &.theme_bg {
    background: $theme_color_deep;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 575px) {
    width: 100% !important;
  }

  .slide_text_section {
    position: absolute;
    top: 15px;
    left: 50px;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 575px) {
      top: 15px;
      bottom: 15px;
      left: 25px;
    }
    .price {
      font-size: 25px;
      color: $white;
      line-height: 1.2;
    }

    .game_description {
      .game_name {
        font-size: 30px;
        font-weight: 600;
        line-height: 1;
        @media (max-width: 575px) {
          font-size: 25px;
        }
      }
      .game_provider {
        font-size: 14px;
        line-height: 1.8;
        font-weight: 400;
        opacity: 0.7;
      }
    }
    .game_arrow {
      font-size: 30px;
      color: $white;
      align-items: center;
      .join_text {
        font-size: 16px;
        padding-bottom: 5px;
        line-height: 30px;
      }
    }
  }
}

.home.in_play {
  min-height: 100vh;
}

.game_play {
  .game_content {
    height: 728px;
    // @media (max-width: 1199px) {
    //   border-radius: 15px 15px 0 0;
    // }

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 25px $black;
    }
    img.placeholder {
      height: auto;
      opacity: 0;
    }
    iframe {
      // position: absolute;
      // top: 0;
      // left: 0;
      // z-index: 20 !important;
    }
  }
  .frame_bottom {
    border: 1px solid $theme_color_deep;
    background: $black_bg;
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    .actions_icon {
      display: flex;
      gap: 20px;
      padding-right: 20px;
      color: #b1bad3;
      align-items: center;
      border-right: 2px solid #b1bad3;
      .currency_select {
        background: #2f4553;
        color: #b1bad3;
        padding: 3px 15px;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 15px;
      }
      i {
        cursor: pointer;
      }
      a {
        color: $white;
      }
    }
    .actions_btn {
      color: #b1bad3;

      .custom-control-input:checked ~ .custom-control-label::before {
        color: #b1bad3;
        border-color: #008a01;
        background-color: #008a01;
      }
      .custom-control-input {
        border: none;
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .game_badge {
    .accordion {
      background: #0f212e !important;
      border-radius: 5px !important;
      .accordion-item {
        background: #0f212e !important;
        border-radius: 5px !important;
        .accordion-header {
          .accordion-button {
            display: block !important;
            background: #0f212e !important;
            background-color: #0f212e !important;
            &::after {
              display: none !important;
            }
            &:focus {
              box-shadow: none !important;
            }
            .header_flex {
              display: flex !important;
              justify-content: space-between !important;
              .text_area {
                color: $white;
                span {
                  color: #7d8386 !important;
                  margin-left: 15px !important;
                }
              }
              .icon_area {
                color: #b1bad3;
                background: #2f4553;
                padding: 5px 15px;
                border-radius: 8px;
                display: flex;
                gap: 5px;
                font-size: 12px;
                align-items: center;
                .golden {
                  color: #cfc36e !important;
                }
              }
            }
          }
        }
        .accordion-body {
          .tabs {
            display: flex;
            border-radius: 3rem;
            padding: 5px;
            flex-shrink: 0;
            background: #1a2c38 !important;
            width: fit-content;
            gap: 15px;
            li {
              list-style: none;
              cursor: pointer;
              padding: 10px 15px;

              text-align: center;
              border-radius: 3rem;
              color: $white;
              font-size: 13px;
              &:hover {
                background: #2f4553;
              }
              &.active {
                background: #2f4553;
              }
            }
          }
          .description {
            display: flex;
            gap: 20px;
            .game_img {
              img {
                width: 200px;
                border-radius: 10px;
              }
            }
            .details {
              .lavels {
                li {
                  list-style: none;
                  width: fit-content;
                  margin-right: 10px;
                  float: left;
                  color: #b1bad3;
                  background: #2f4553;
                  padding: 0px 8px;
                  border-radius: 10px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.theaterModeEnabled {
    width: 70%;
    height: auto;
    margin: 0 auto;
  }

  .game_details {
    background: #282829;
    border-radius: 15px;
    padding: 15px 15px 0 15px;
    height: 100%;

    @media (min-width: 501px) and (max-width: 1199px) {
      display: flex;
      justify-content: space-around;
    }

    @media (max-width: 1199px) {
      border-radius: 0 0 15px 15px;
    }
    .headiing {
      font-size: 20px;
      color: #f9f9f9;
      line-height: 1;
    }
    .single_details_block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 15px 0;

      &.players {
        align-items: center;
        grid-template-columns: unset;
        .item {
          border-right: none;
        }
      }
      .item {
        padding: 5px;
        border-right: 1px solid #4d5c79;
        text-align: center;
        @media (max-width: 500px) {
          border: none;
          // text-align: left !important;
        }
        &.active {
          background: $theme_color_light;
          .item_name {
            color: $white;
          }
          .item_value {
            color: $white;
          }
        }
        &:nth-child(1) {
          padding-left: 0;
          text-align: left;
        }
        &:nth-last-child(1) {
          padding-right: 0;
        }
        .game_block {
          display: flex;
          align-items: center;

          .game_thumb {
            img {
              width: 60px;
              height: 40px;
              border: 2px solid #374869;
              border-radius: 5px;
            }
          }
          .game_name {
            font-size: 16px;
            color: $white;
            padding-left: 10px;
            align-items: center;

            .level {
              height: 20px;
              width: 20px;
              display: block;
              border-radius: 50%;
              margin-left: 10px;
              font-size: 14px;
              color: $dark_blue_bg;
              text-align: center;
              background: $theme_color_light;

              .supper {
                color: $white;
              }
            }
          }
        }

        .item_name {
          color: #bdbdbd;
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          &.white {
            color: $white;
          }
        }
        .item_value {
          color: #616672;
          font-size: 13px;
        }
      }
    }
  }
}

.providers_modal {
  background: $site_bg;
  border-radius: 8px;
  padding: 30px 30px !important;
  margin: 0 auto;
  width: 100%;
  color: $white;
  @media (max-width: 575px) {
    padding: 10px !important;
  }
  .modal_close {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #454568;
    color: #a8ace2;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: -20px;
    top: -20px;
    cursor: pointer;
    @media (max-width: 767px) {
      right: 5px;
      top: 5px;
    }
  }
  .modal_headline {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    color: #dddff8;
  }
  .providers_list {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    grid-gap: 10px;
    overflow-y: auto;
    max-height: 70vh;
    .common_btn {
      padding: 0 10px !important;
      width: 100%;
      @media (max-width: 767px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.category_games {
  // .custom_slider {
  //   overflow: hidden;
  //   margin-bottom: 50px;
  //   position: relative;
  //
  //   &:focus {
  //     outline: none;
  //   }
  //   .flickity-viewport {
  //     .flickity-slider {
  //       position: absolute;
  //       width: 100%;
  //       height: 100%;
  //       left: 0;
  //     }
  //   }
  // }

  .custom_slider {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;
    margin-bottom: 50px;
    position: relative;
    justify-content: center;
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
  }
}

.single_slide {
  // max-width: 500px;
  position: relative;
  // max-height: 315px;
  .slide_bg_image {
    width: 100%;
    // opacity: 0.9;
  }

  @media (max-width: 575px) {
    width: 100% !important;
  }

  .label_img {
    position: absolute;
    top: 0px;
    left: 50px;
    z-index: 1;
  }
  .slide_text_section {
    width: 160px;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    .heading_text {
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
      color: $white;
      span {
        color: $theme_color_deep;
      }
    }
    .info_text {
      margin-top: 10px;
      color: $white;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &:hover {
    .slide_bg_image {
      -webkit-filter: brightness(70%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
    }
  }
}

.category_games {
  margin: 30px 0;
  @media (max-width: 767px) {
    .container {
      padding: 0 !important;
    }
  }
  .custom_slider {
    overflow: hidden;
    margin-bottom: 50px;
    position: relative;

    &:focus {
      outline: none;
    }
    .flickity-viewport {
      .flickity-slider {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
      }
    }
  }
  &.flickity_games {
    .single_game {
      // position: relative !important;
      // left: 0px;
      // transform: unset !important;

      margin-right: 15px;
      margin-left: 15px;
      // max-width: 214px;
      // width: 100%;
      // @media (min-width: 1400px) {
      //   max-width: 235px;
      // }

      // @media (min-width: 992px) and (max-width: 1199px) {
      //   max-width: 185px;
      // }
      @media (min-width: 768px) and (max-width: 991px) {
        // max-width: 162px;
        margin-right: 7.5px;
        margin-left: 7.5px;
      }

      // @media (max-width: 767px) {
      //   max-width: 175px;
      //   margin-right: 15px;
      // }
    }
  }
}
.category_games.flickity_games {
  .slick-list {
    margin-right: -12px;
    margin-left: -12px;
    @media (min-width: 768px) and (max-width: 991px) {
      // max-width: 162px;
      margin-right: -7.5px;
      margin-left: -7.5px;
    }
  }
}

.category_games.gameSuggestions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0px;
  gap: 10px;
}
// .slick-slide.slick-active {
//   &:nth-last-child {
//     .single_game {
//       margin-right: 0px !important;
//     }
//   }
// }

.category_games_header {
  display: flex;
  justify-content: space-between;
  margin: 45px 0 10px 0;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 20px;
  }

  .game_headline {
    color: $white;
    line-height: 1;
    margin-bottom: 0;
    svg {
      margin-right: 10px;
    }

    .icon {
      width: 35px;
      margin-right: 5px;
      vertical-align: sub;
    }
  }
  .slider_controlls {
    .prev {
      background: none;
      box-shadow: none;
      margin: 0 5px 0 0;
      padding: 0;
      border: none;
      opacity: 0.5;
      &:focus {
        opacity: 1;
        background: none !important;
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
      }
      &:hover {
        opacity: 1;
        background: none !important;
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
      }
      &.next {
        margin: 0 0 0 5px;
      }
      svg {
        fill: $white;
      }
    }
  }

  .game_provider_dropdoun {
    position: relative;
    .provider_filter_btn {
      font-size: 0.875em;
      border: 0px;
      color: $white;
      margin: 0px;
      padding: 12px 20px;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      background-color: #343434 !important;
      width: 100%;

      .selected {
        color: $hoveColor;
        margin: 0 5px;
      }
      &::after {
        content: none;
      }
      &:focus,
      &:active {
        background-color: $black !important;
        outline: 0 !important;
        border: 0 !important;
        box-shadow: none !important;
      }

      &:hover,
      &:active {
        background: $black;
        // background: rgba(18, 24, 51, 0.8);
      }
    }

    .provider_menu {
      background: $black;
      border-radius: 3px;
      min-width: 245px;
      max-width: 245px;
      padding: 15px 0;

      .provider_list {
        max-height: 300px;
        overflow-y: auto;
        .item {
          padding: 0 15px;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: none;
            color: $hoveColor !important;
          }
          label {
            margin-bottom: 0;
            .check_check_and_text_area {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .common_headline_with_bg {
    padding: 0px;
  }
}
.sub_game_menu {
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  ul {
    li {
      margin: 0 5px;
      display: inline-block;
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
      a {
        display: block;
        padding: 5px 10px;
        color: $white;
        border: 1px solid $white;
        text-decoration: none;
        &:hover,
        &.active {
          color: $green;
          border: 1px solid $green;
        }
      }
    }
  }
}
main.in_play {
  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    height: calc(100% + 1px);
  }
}
.mobile_game_frame {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 20 !important;
  width: 100%;
  height: calc(100vh - 102px);
}
