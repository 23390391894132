.support_panel {
  width: 435px;
  position: fixed;
  top: 0;
  right: -435px;
  height: 100vh;
  z-index: 5000;
  padding: 20px;
  // background: $black;
  background: linear-gradient(90deg, rgb(15, 19, 25), rgb(6, 9, 14));
  transition: all 0.3s;
  overflow-y: auto;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  border-left: 2px solid $theme_color_deep;
  @media (max-width: 767px) {
    max-width: 100vw;
  }
  &.open {
    right: 0;
  }
  .close_icon {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    // background: #454568;
    color: $white;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    right: 0px;
    top: 10px;
    cursor: pointer;
    &:hover {
      color: $theme_color_deep;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  .tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    a {
      display: block;
      text-align: center;
      border-radius: 5px 5px 0 0;
      line-height: 35px;
      width: 100%;
      text-decoration: none;
      color: $white;
      background-color: grey;
      &.active {
        background: -webkit-gradient(linear, left top, left bottom, from($theme_color_deep), to($theme_color_light));
        background: linear-gradient(to bottom, $theme_color_deep 0%, $theme_color_light 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$theme_color_deep', endColorstr='$theme_color_light',GradientType=0 );
      }
    }
  }

  .panel_header {
    color: $white;
  }
  .support_sidebar_content {
    width: 100%;
    height: calc(100% - 100px);
    padding-top: 15px;
    // .accordion {
    //   // border: 1px solid $theme_color_light;
    //   .accordion-header {
    //     .accordion-button {
    //       font-weight: bold;
    //       font-size: 16px;
    //       margin: 0;
    //       box-shadow: none;
    //       position: relative;
    //       padding: 0 15px;
    //       line-height: 50px;
    //       -webkit-transition: all 0.2s;
    //       transition: all 0.2s;

    //       &:not(.collapsed) {
    //         background: rgba($theme_color_light, 0.3);
    //         color: $black;
    //       }
    //     }
    //   }

    //   .accordion-body {
    //     padding: 10px;
    //     .form-control {
    //       margin-bottom: 15px;
    //       border: none;
    //       outline: none;
    //       input {
    //         outline: none;
    //       }
    //     }
    //   }
    // }

    .email_us {
      color: $white;
      padding: 15px 0;
      .contact_us_area {
        margin-top: 15px;
        .reg_field {
          margin-bottom: 15px;
          input,
          textarea {
            box-shadow: none;
            &:focus {
              border: 1px solid $theme_color_light;
            }
          }
        }
      }
    }

    .support_chat {
      width: 100%;
      height: calc(100% - 0px);
    }
  }
}

.my_tickets_area {
  margin-top: 20px;
  .header {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .accordion .accordion-header .accordion-button {
    a {
      color: $white;
      text-decoration: none;
    }
  }
  .accordion .accordion-header .accordion-button:not(.collapsed) {
    a {
      color: #000;
    }
  }
}
